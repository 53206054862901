import { Center, Checkbox, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import RuleType from '../../common/RuleType';
import { ProductDto } from '../../core/products/types';
import ProductIsPurchasablePin from '../Product/ProductIsPurchasablePin';
import RuleTypeElement from '../Rule/RuleTypeElement';
import { CenterFlex, ProductIdCell } from '../Table/utils';

type StockResultsItemProps = {
  isChecked: boolean;
  toggle: (id: string, selected: boolean) => void;
  onClick: (sku: string, inventoryId?: string) => void;
} & ProductDto;
const StockResultsItem: FC<StockResultsItemProps> = ({
  id,
  name,
  collection_code,
  country,
  sf_quantity,
  rule,
  automatic_quantity,
  is_archived,
  is_available,
  is_searchable,
  is_perpetual,
  isChecked,
  storeCount,
  totalStock,
  toggle,
  onClick,
}) => {
  const rowRule = RuleType.fromName(rule?.type);
  const additionalColumns = country ? (
    <>
      <CenterFlex>
        <Text textAlign="center">{`${totalStock ?? '-'}`}</Text>
      </CenterFlex>
      <CenterFlex>
        <Text textAlign="center">{`${storeCount ?? '-'}`}</Text>
      </CenterFlex>
      <CenterFlex>
        <RuleTypeElement rule={rule} perpetual={is_perpetual} />
      </CenterFlex>
      <CenterFlex>
        <ProductIsPurchasablePin
          country={country}
          is_archived={is_archived}
          is_available={is_available}
          is_searchable={is_searchable}
          is_perpetual={is_perpetual}
          sf_quantity={sf_quantity}
          id={id}
        />
      </CenterFlex>
      <CenterFlex>
        <Text textAlign="center">{`${sf_quantity ?? '-'}`}</Text>
      </CenterFlex>
      <CenterFlex>
        <Text textAlign="center" fontWeight={rowRule === RuleType.Manual ? 'bold' : 'normal'}>
          {`${rule?.quantity ?? '-'}`}
        </Text>
      </CenterFlex>
      <CenterFlex>
        <Text fontWeight={rowRule === RuleType.Automatic ? 'bold' : 'normal'}>
          {automatic_quantity ?? '-'}
        </Text>
      </CenterFlex>
    </>
  ) : (
    <CenterFlex>
      <Text>{collection_code}</Text>
    </CenterFlex>
  );

  return (
    <Flex w={'100%'} alignItems="center">
      <Center px={3}>
        <Checkbox
          isChecked={isChecked}
          onChange={element => toggle(id, element.currentTarget.checked)}
        />
      </Center>
      <Center w={'100%'}>
        <Flex
          w={'100%'}
          direction={{
            base: 'column',
            md: 'row',
          }}
          cursor={'pointer'}
          onClick={() => onClick(id, country?.inventoryId)}
        >
          <ProductIdCell width="35%" id={id} name={name} />
          <Flex w="65%">{additionalColumns}</Flex>
        </Flex>
      </Center>
    </Flex>
  );
};

export default StockResultsItem;
