import { RuleDto, UpdateRuleDto } from './types';

export class UpdateRuleDtoBuilder {
  static fromRuleDto(source: RuleDto): UpdateRuleDto {
    const updateRuleDto: UpdateRuleDto = {
      sku: source.sku,
      countryCode: source.countryCode,
      type: source.type,
      lifetime: source.lifetime,
      quantity: source.quantity,
      author: source.author,
    };

    return updateRuleDto;
  }

  static from(
    sku: string,
    countryCode: string,
    type: string,
    lifetime?: string,
    quantity?: number,
  ): UpdateRuleDto {
    const updateRuleDto: UpdateRuleDto = {
      sku: sku,
      countryCode: countryCode,
      type: type,
      lifetime: lifetime,
      quantity: quantity,
    };

    return updateRuleDto;
  }
}
