import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  useDisclosure,
  useRadioGroup,
} from '@chakra-ui/react';
import moment from 'moment';
import { ChangeEventHandler, FC, useState } from 'react';
import RuleType from '../../common/RuleType';
import { LogsDto } from '../../core/logs-rule/types';
import { RuleDto } from '../../core/rules/types';
import { logsService, rulesService } from '../../core/services';
import LogsModal from '../UI/LogsModal';
import RuleTypeOption from './RuleTypeOption';

type RuleTypeControlProps = {
  ruleType: RuleType;
  onChange: (nextValue: string) => void;
  automaticQuantiy?: number;
  manualQuantity?: number;
  updatedDate?: Date;
  isDisabled?: boolean;
  author?: string;
  sku?: string;
  country?: string;
};
export const RuleTypeControl: FC<RuleTypeControlProps> = ({
  ruleType,
  onChange,
  automaticQuantiy,
  manualQuantity,
  updatedDate,
  author,
  sku,
  country,
  ...rest
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: ruleType.name,
    onChange: onChange,
  });

  const group = getRootProps();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentRule, setCurrentRule] = useState<RuleDto>();
  const [ruleLogs, setRuleLogs] = useState<LogsDto | LogsDto[]>([]);

  const openModal = () => {
    onOpen();
    sku &&
      country &&
      logsService.fetchRelated({ sku: sku, countryCode: country }).then(data => {
        setRuleLogs(data);
      });
    sku &&
      country &&
      rulesService.fetchRules({ sku: sku, countryCode: country }).then(data => {
        setCurrentRule(data);
      });
  };

  return (
    <>
      <FormControl {...rest}>
        <Flex direction={'row'} justifyContent={'space-between'}>
          <FormLabel>Type</FormLabel>
          {updatedDate && (
            <Button
              border={'1px'}
              borderColor={'gray.500'}
              mb={3}
              onClick={openModal}
            >{`Last rule update`}</Button>
          )}
        </Flex>
        <Stack {...group}>
          <RuleTypeOption
            type={RuleType.Automatic}
            radioProps={getRadioProps}
            currentValue={automaticQuantiy}
          />
          <RuleTypeOption type={RuleType.Perpetual} radioProps={getRadioProps} />
          <RuleTypeOption
            type={RuleType.Manual}
            radioProps={getRadioProps}
            currentValue={manualQuantity}
          />
        </Stack>
      </FormControl>
      <LogsModal
        isOpen={isOpen}
        onClose={onClose}
        header={`Latest rules update - ${sku}`}
        subheader={`${country}`}
        ruleHistory={ruleLogs}
        currentRule={currentRule}
      />
    </>
  );
};

type RuleLifetimeControlProps = {
  ruleType: RuleType;
  onChange: ChangeEventHandler<HTMLInputElement>;
  lifetime?: string;
};
export const RuleLifetimeControl: FC<RuleLifetimeControlProps> = ({
  ruleType,
  onChange,
  lifetime,
}) => {
  return (
    <FormControl>
      <FormLabel _disabled={{ color: 'grey' }}>Valid until...</FormLabel>
      <Input
        name="lifetime"
        type="datetime-local"
        defaultValue={(lifetime ? moment(lifetime) : moment()).format('YYYY-MM-DDTHH:mm')}
        onChange={onChange}
        isDisabled={ruleType === RuleType.Automatic}
      />
    </FormControl>
  );
};

type RuleQuantityControlProps = {
  ruleType: RuleType;
  onChange: (value: string) => void;
  quantity?: number;
};
export const RuleQuantityControl: FC<RuleQuantityControlProps> = ({
  ruleType,
  onChange,
  quantity,
}) => {
  return (
    <FormControl>
      <FormLabel _disabled={{ color: 'grey' }}>Quantity</FormLabel>
      <NumberInput
        name="quantity"
        defaultValue={quantity}
        min={0}
        onChange={onChange}
        isDisabled={ruleType !== RuleType.Manual}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  );
};
