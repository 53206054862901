import { ChakraProvider } from '@chakra-ui/react';
import SwatchTheme from './themes/swatch';
import Pages from './pages';
import Fonts from './components/UI/Fonts';

export const App = () => (
  <ChakraProvider theme={SwatchTheme}>
    <Fonts />
    <Pages />
  </ChakraProvider>
);
