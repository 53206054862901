import {
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { WarningAlert } from '../../common/Alerts';
import { exportToClipboard, exportToExcel } from '../../common/export';
import { ConvertToExport } from '../../common/product/export';
import { usePagination } from '../../contexts/PaginationContext';
import { useProduct } from '../../contexts/ProductContext';
import { useSearch } from '../../contexts/SearchContext';
import { useSorting } from '../../contexts/SortingContext';
import { exportAll } from '../../core/export';
import OrdersHistory from '../Orders/OrdersHistory';
import RulesEditor from '../Rule/RulesEditor';
import ResultTable from '../Table/ResultTable';
import ConfirmModal from '../UI/ConfirmModal';
import LoadingModal from '../UI/LoadingModal';
import MenuActions from '../UI/MenuActions';
import Paginator from '../UI/Paginator';
import StockResultsHeader from './StockResultsHeader';
import StockResultsItem from './StockResultsItem';

interface StockResultsProps extends BoxProps {
  children?: React.ReactNode;
}

const StockResults = ({ children, ...rest }: StockResultsProps) => {
  const { setSku, setInventory } = useProduct();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, dataList, role, fetch, error } = useSearch();
  const { total, totalAssortment, pageIndex, pageSize } = usePagination();
  const { sort } = useSorting();
  const [rows, setRows] = useState<any[]>([]);
  const [loaddAll, setLoaddAll] = useState<boolean>(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const {
    isOpen: isRulesEditorOpen,
    onOpen: onRulesEditorOpen,
    onClose: onRulesEditorClose,
  } = useDisclosure();

  const { isOpen: isOpenOrders, onOpen: onOpenOrders, onClose: onCloseOrders } = useDisclosure();

  const [selectedSkus, setSelectedSkus] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectionStatus, setSelectionStatus] = useState<'all' | 'none' | 'some'>('none');

  const toggleProduct = useCallback(
    (id: string, selected: boolean) => {
      if (selected && !selectedSkus.includes(id)) {
        setSelectedSkus(prev => [...prev, id]);
      } else if (!selected) {
        setSelectedSkus(prev => prev.filter(sku => sku !== id));
      }
    },
    [selectedSkus],
  );

  // Handlers
  const handleOnClose = () => {
    onClose();
  };

  const showProduct = useCallback(
    (id: string, inventoryId?: string) => {
      setSku(id);
      setInventory(inventoryId);
      onOpen();
    },
    [onOpen, setInventory, setSku],
  );

  useEffect(() => {
    setSelectedSkus([]);
    setSelectedCountries([]);
  }, [fetch]);

  useEffect(() => {
    fetch(pageIndex, pageSize);
  }, [fetch, pageSize, pageIndex]);

  useEffect(() => {
    const sortedData = [...dataList.data].sort((a, b) => sort.sortFn()(a, b));
    setRows(
      dataList.data !== undefined
        ? sortedData.map((product, i) => {
            return (
              <StockResultsItem
                key={i}
                {...product}
                isChecked={selectedSkus.includes(product.id)}
                toggle={toggleProduct}
                onClick={showProduct}
              />
            );
          })
        : [],
    );
    setSelectedCountries(
      dataList.data !== undefined
        ? dataList.data.map(p => p.country?.code ?? '').filter(code => !!code)
        : [],
    );
  }, [dataList, sort, selectedSkus, showProduct, toggleProduct]);

  useEffect(() => {
    if (selectedSkus.length === 0) {
      setSelectionStatus('none');
    } else {
      const isAllSelected = dataList.data.map(p => p.id).every(s => selectedSkus.includes(s));
      setSelectionStatus(isAllSelected ? 'all' : 'some');
    }
  }, [dataList, selectedSkus]);

  const openRulesEditor = () => {
    onRulesEditorOpen();
  };

  const getExportProducts = () => {
    return dataList?.data
      .filter(p => selectedSkus.includes(p.id))
      .sort(sort.sortFn())
      .map(p => ConvertToExport(p));
  };

  const copyAction = () => {
    const exportProducts = getExportProducts();
    const excelText = exportToClipboard(exportProducts);
    navigator.clipboard
      .writeText(excelText)
      .then(() => {
        console.log('has copied !');
      })
      .catch(e => console.error('An error occured while copying', e));
  };

  const exportToExcelAction = () => {
    const exportProducts = getExportProducts();
    exportToExcel(exportProducts);
  };

  const [currentStart, setCurrentStart] = useState(0);

  const getExportData = async (inventory: string | undefined) => {
    if (inventory) {
      setLoaddAll(true);
      const data = await exportAll(
        { inventoryId: inventory, skus: selectedSkus, name: '' },
        totalAssortment,
        start => {
          setCurrentStart(start);
        },
      );
      setLoaddAll(false);
      return data.flat().map(p => ConvertToExport(p));
    }
  };

  const exportAllToExcelAction = () => {
    setIsConfirmModal(true);
  };

  const confirmExportAllToExcel = async () => {
    setIsConfirmModal(false);
    const exportAllProducts = await getExportData(dataList.data[0]?.country?.inventoryId);
    if (exportAllProducts) {
      exportToExcel(exportAllProducts);
    }
  };

  const menuItems = [
    ...(role.canEdit && selectedSkus.length !== 0
      ? [
          {
            text: 'Add a rule for...',
            action: openRulesEditor,
          },
          {
            text: 'Search orders...',
            action: onOpenOrders,
          },
        ]
      : []),
    ...(selectedSkus.length !== 0
      ? [
          {
            text: 'Copy...',
            action: copyAction,
          },
          { text: 'Export...', action: exportToExcelAction },
        ]
      : []),
    ...(dataList.data[0]?.country?.inventoryId && selectedSkus.length === 0
      ? [
          {
            text: 'Export All...',
            action: exportAllToExcelAction,
          },
        ]
      : []),
  ];

  return (
    <>
      <Stack {...rest}>
        {error && <WarningAlert title={`Error ${error.statusCode}`} description={error.status} />}
        <HStack>
          <Heading size="md">{`Results${!isLoading && total ? ` (${total})` : ''}`}</Heading>
          <Spacer />
          <MenuActions
            items={menuItems}
            isDisabled={
              isLoading ||
              (dataList.data[0]?.country?.inventoryId === undefined && selectedSkus.length === 0)
            }
          >
            <RulesEditor
              selectedSkus={selectedSkus}
              selectedCountries={selectedCountries}
              isOpen={isRulesEditorOpen}
              onClose={onRulesEditorClose}
            />

            <OrdersHistory
              sku={selectedSkus}
              isOpen={isOpenOrders}
              country={dataList.data[0]?.country?.code}
              onClose={onCloseOrders}
            />
          </MenuActions>
        </HStack>
        {!isLoading && (
          <StockResultsHeader
            hasCountry={dataList.data?.find(p => p.country !== undefined) !== undefined}
            selectionStatus={selectionStatus}
            selectAll={(selected: boolean) => {
              dataList.data.forEach(product => toggleProduct(product.id, selected));
            }}
          />
        )}
        <ResultTable items={rows} isLoading={isLoading} />
        {!isLoading && <Paginator />}
      </Stack>
      <Drawer onClose={handleOnClose} isOpen={isOpen} size={'xl'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
      <ConfirmModal
        isOpen={isConfirmModal}
        onClose={() => setIsConfirmModal(false)}
        onConfirm={confirmExportAllToExcel}
        header="Are you sure you want to export all items ?"
        subheader="This will take several minutes, and you'll have to wait until the procedure is complete."
      />
      <LoadingModal
        isOpen={loaddAll}
        onClose={() => {}}
        header="Exporting all items"
        subheader={dataList.data[0]?.country?.inventoryId}
        start={currentStart}
        total={totalAssortment}
      />
    </>
  );
};

export default StockResults;
