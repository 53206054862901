import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  SkeletonCircle,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { WarningAlert } from '../../common/Alerts';
import { useProduct } from '../../contexts/ProductContext';
import { useSearch } from '../../contexts/SearchContext';
import { ApiError } from '../../core/api/types';
import { ProductDto } from '../../core/products/types';
import { productsService } from '../../core/services';
import RuleEditor from '../Rule/RuleEditor';
import RuleViewer from '../Rule/RuleViewer';
import ProductDetailHeader from './ProductDetailHeader';
import ProductEcomInfo from './ProductEcomInfo';
import ProductImagesCarousel from './ProductImagesCarousel';
import ProductInfo from './ProductInfo';
import ProductIsPurchasablePin from './ProductIsPurchasablePin';
import ProductStock from './ProductStocks';

type ProductDetailProps = {};
const ProductDetail: FC<ProductDetailProps> = () => {
  const [isReloading, setIsReloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [productDetail, setProductDetail] = useState<ProductDto>();
  const [error, setError] = useState<ApiError>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { sku, inventory, setInventory } = useProduct();
  const [hasInventory] = useState(!!inventory);
  const { role } = useSearch();

  useEffect(() => {
    productsService
      .getProduct(sku, hasInventory ? inventory : undefined)
      .then(setProductDetail)
      .then(() => setIsLoading(false))
      .then(() => setIsReloading(false))
      .catch(setError);
  }, [sku, isReloading, hasInventory, inventory]);

  const handleClickedRow = useCallback(
    (productId: string, countryCode: string) => {
      const selectedProduct = productDetail?.products?.find(
        p => p.id === productId && p.country?.code === countryCode,
      );
      setInventory(selectedProduct?.country?.inventoryId);
      onOpen();
    },
    [onOpen, productDetail?.products, setInventory],
  );

  const handleOnClose = () => {
    setInventory(undefined);
    onClose();
    setIsReloading(true);
  };

  const handleProductUpdate = () => {
    setIsReloading(true);
    setIsLoading(true);
  };

  const content = isLoading ? (
    <GridItem colSpan={2}>
      <Center>
        <SkeletonCircle size={'50'} startColor={'whiteAlpha.100'} endColor={'yellow.400'} />
      </Center>
    </GridItem>
  ) : error ? (
    <GridItem colSpan={2}>
      <WarningAlert title={`Error ${error.statusCode}`} description={error.status} />
    </GridItem>
  ) : (
    <>
      <GridItem
        gridRow={{ base: '1 / 2', md: '1 / 3' }}
        gridColumn={2}
        rowSpan={{ base: 2, md: 3 }}
        alignSelf={'center'}
      >
        <ProductImagesCarousel sku={sku} pictures={productDetail?.pictures} />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }} width={'auto'}>
        {productDetail && (
          <ProductEcomInfo {...productDetail} onProductUpdate={handleProductUpdate} />
        )}
      </GridItem>

      {productDetail?.products && (
        <GridItem colSpan={2}>
          <ProductStock
            w="100%"
            products={productDetail.products}
            onRowClicked={handleClickedRow}
          />
        </GridItem>
      )}
      {productDetail?.country && (
        <GridItem colSpan={2}>
          {role.canEdit ? (
            <RuleEditor product={productDetail} country={productDetail.country} />
          ) : (
            <RuleViewer product={productDetail} />
          )}
        </GridItem>
      )}
    </>
  );

  return (
    <Box>
      <Grid
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(auto-fit, minmax(0, 1fr))"
        gap="1em"
      >
        <GridItem colSpan={1}>
          <Flex alignItems={'baseline'} gap={2}>
            <ProductDetailHeader
              productId={sku}
              inventoryId={hasInventory ? inventory : undefined}
            />
            {productDetail && <ProductIsPurchasablePin {...productDetail} />}
          </Flex>
          {productDetail && <ProductInfo {...productDetail} />}
        </GridItem>
        {content}
      </Grid>
      <Drawer onClose={handleOnClose} isOpen={isOpen} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>{inventory && <ProductDetail />}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ProductDetail;
