import {
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';

type LoadingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  start: number;
  total: number;
  subheader?: string;
};

const LoadingModal: FC<LoadingModalProps> = ({
  isOpen,
  onClose,
  header,
  start,
  total,
  subheader,
}) => {
  const percentage = total !== 0 ? (start / total) * 100 : 0;

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered size={'lg'}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
      <ModalContent>
        <ModalHeader>
          {header} {subheader && `of ${subheader}`}
        </ModalHeader>
        <ModalBody pb={6}>
          <Progress hasStripe value={percentage} colorScheme={'green'} />
          <Center pt={5}>
            <Text fontSize={'md'} color={'blue.400'}>
              {start} of{' '}
              <Text as={'b'} color={'cyan.400'}>
                {total}
              </Text>{' '}
              items were processed for export
            </Text>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
