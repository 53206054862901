import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';

type ConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  header: string;
  subheader: string;
};

const ConfirmModal: FC<ConfirmModalProps> = ({ isOpen, onClose, header, subheader, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={'lg'}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
      <ModalContent>
        <ModalHeader>
          <Flex align="center">
            <Icon as={WarningTwoIcon} w={5} h={5} color="yellow.300" mr={2} />
            {header}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{subheader}</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm}>
            Proceed
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
