import { Button, HStack, Select, Spacer, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { usePagination } from '../../contexts/PaginationContext';

const pageSizeOptions = [15, 30, 45, 60, 85, 100];

const RetailStockPaginator = () => {
  const {
    pageIndex,
    pageSize,
    setPageSize,
    pageCount,
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    previousPage,
  } = usePagination();

  // Reset to first page when page size changes to prevent being on an invalid page
  useEffect(() => {
    if (pageIndex >= pageCount) {
      gotoPage(0);
    }
  }, [pageSize, pageIndex, pageCount, gotoPage]);

  return (
    <HStack>
      <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        <FiChevronsLeft aria-label="first page" />
      </Button>
      <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <FiChevronLeft aria-label="previous page" />
      </Button>
      <Spacer />
      <Text>{`Page ${pageIndex + 1} of ${pageCount}`}</Text>
      <Select
        value={pageSize}
        onChange={e => setPageSize(Number(e.target.value))}
        size="sm"
        width="110px"
      >
        {pageSizeOptions.map(option => (
          <option key={option} value={option}>
            {`${option} items`}
          </option>
        ))}
      </Select>
      <Spacer />
      <Button onClick={() => nextPage()} disabled={!canNextPage}>
        <FiChevronRight aria-label="next page" />
      </Button>
      <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FiChevronsRight aria-label="last page" />
      </Button>
    </HStack>
  );
};

export default RetailStockPaginator;
