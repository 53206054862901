import ApiClient from '../api/ApiClient';
import { DataList } from '../api/types';
import { Pagination, ProductDto, ProductFilterDto, ProductUpdateDto } from './types';

export class ProductsService {
  private readonly apiBase: string;
  private readonly apiBaseProd: string;
  private readonly apiBaseAssortment: string;
  private readonly apiClient: ApiClient;

  constructor() {
    this.apiBase = 'products';
    this.apiBaseProd = 'products/update';
    this.apiBaseAssortment = 'products/assortment';
    this.apiClient = new ApiClient();
  }

  async searchProducts(filter: ProductFilterDto & Pagination): Promise<DataList<ProductDto>> {
    return this.apiClient.post(this.apiBase, filter);
  }

  async searchProductsAssortment(
    filter: ProductFilterDto & Pagination,
  ): Promise<DataList<ProductDto>> {
    return this.apiClient.post(this.apiBaseAssortment, filter);
  }

  async getProduct(productId: string, inventoryId?: string): Promise<ProductDto> {
    const config = !!inventoryId
      ? {
          params: {
            inventoryId: inventoryId,
          },
        }
      : undefined;
    return this.apiClient.get(`${this.apiBase}/${productId}`, config);
  }

  async getProductsSuggestions(partialSku: string): Promise<string[]> {
    return this.apiClient.get(`${this.apiBase}/suggestions/${partialSku}`);
  }

  async updateProduct(
    productId: string,
    productUpdateDto: ProductUpdateDto,
  ): Promise<ProductUpdateDto> {
    return this.apiClient.patch(`${this.apiBase}/${productId}`, productUpdateDto);
  }

  async updateProductProd(
    productId: string,
    productUpdateDto: ProductUpdateDto,
  ): Promise<ProductUpdateDto> {
    return this.apiClient.patch(`${this.apiBaseProd}/${productId}`, productUpdateDto);
  }
}
