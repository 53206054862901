import { Center, Checkbox, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import RuleType from '../../common/RuleType';
import { ProductDto } from '../../core/products/types';
import RuleTypeElement from '../Rule/RuleTypeElement';
import { CenterFlex } from '../Table/utils';
import ProductIsPurchasablePin from './ProductIsPurchasablePin';

type ProductStocksItemProps = {
  isChecked: boolean;
  toggle: (countryCode: string, selected: boolean) => void;
  onClick: (sku: string, countryCode: string) => void;
} & ProductDto;
const ProductStocksItem: FC<ProductStocksItemProps> = ({
  id,
  country,
  rule,
  sf_quantity,
  automatic_quantity,
  is_archived,
  is_available,
  is_searchable,
  is_perpetual,
  isChecked,
  e_reservation,
  is_p40,
  toggle,
  onClick,
}) => {
  let rowRule;
  if (rule) {
    rowRule = RuleType.fromName(rule?.type);
  } else if (is_perpetual === true) {
    rowRule = RuleType.Perpetual;
  } else {
    rowRule = RuleType.Automatic;
  }

  return (
    <Flex w={'full'} px={3}>
      <Checkbox
        isChecked={isChecked}
        onChange={element => toggle(country?.code ?? '', element.currentTarget.checked)}
        px={2}
      />
      <Flex
        w={'full'}
        direction={{ base: 'column', md: 'row' }}
        px={3}
        cursor={'pointer'}
        onClick={() => country?.code && onClick(id, country?.code)}
      >
        <Flex flex="1">
          <Text flex={1}>{country?.code}</Text>
          <Center flex={1}>
            <RuleTypeElement rule={rule} perpetual={is_perpetual} />
          </Center>
        </Flex>
        <Flex flex="1">
          <CenterFlex>
            <ProductIsPurchasablePin
              country={country}
              is_archived={is_archived}
              is_available={is_available}
              is_searchable={is_searchable}
              is_perpetual={is_perpetual}
              sf_quantity={sf_quantity}
              e_reservation={e_reservation}
              is_p40={is_p40}
              id={id}
            />
          </CenterFlex>
          <Text flex={1} textAlign="center">
            {`${sf_quantity ?? '-'}`}
          </Text>
          <Text
            flex={1}
            textAlign="center"
            fontWeight={rowRule === RuleType.Manual ? 'bold' : 'normal'}
          >
            {`${rule?.quantity ?? '-'}`}
          </Text>
          <Text
            flex={1}
            textAlign="center"
            fontWeight={rowRule === RuleType.Automatic ? 'bold' : 'normal'}
          >
            {automatic_quantity ?? '-'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductStocksItem;
