import { Center, Checkbox, Flex, Select } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import {
  defaultFilter,
  FilterFn,
  isEulogFilter,
  isRuleTypeFilter,
  sortByAutomatic,
  sortByCountryCode,
  sortByManual,
  sortBySalesforce,
} from '../../common/product/utils';
import RuleType from '../../common/RuleType';
import SortableColumnHeader from '../Table/SortableColumn';
import { CenterFlex, HeaderText } from '../Table/utils';

interface ProductStocksHeaderProps {
  selectionStatus: 'all' | 'some' | 'none';
  selectAll: (selected: boolean) => void;
  setFilter: (filterFn: FilterFn) => void;
}
const ProductStocksHeader: FC<ProductStocksHeaderProps> = ({
  selectionStatus,
  selectAll,
  setFilter,
}: ProductStocksHeaderProps) => {
  const [isEulog, setIsEulog] = useState(-1);
  const [ruleType, setRuleType] = useState(-1);

  useEffect(() => {
    if (isEulog < 0) {
      setFilter(defaultFilter);
    } else {
      setFilter(p => isEulogFilter(p, isEulog > 0));
    }
  }, [isEulog, setFilter]);

  useEffect(() => {
    ruleType === 0
      ? setFilter(p => isRuleTypeFilter(p, RuleType.Perpetual))
      : ruleType === 1
        ? setFilter(p => isRuleTypeFilter(p, RuleType.Manual))
        : ruleType === 2
          ? setFilter(p => isRuleTypeFilter(p, RuleType.Automatic))
          : setFilter(defaultFilter);
  }, [ruleType, setFilter]);

  return (
    <Flex w={'full'} pb={1} borderBottom={'solid 1px'} borderColor={'gray.200'}>
      <Center px={3}>
        <Checkbox
          isIndeterminate={selectionStatus === 'some'}
          isChecked={selectionStatus === 'all'}
          onChange={element => {
            selectAll(element.currentTarget.checked);
          }}
        />
      </Center>
      <Flex w={'full'} direction={{ base: 'column', md: 'row' }} px={3} gap={{ base: 2, md: 0 }}>
        <Flex flex={1}>
          <Flex flex={1}>
            <SortableColumnHeader
              sortAscending={{
                name: sortByCountryCode.name,
                sortAscendingFn: sortByCountryCode,
              }}
            >
              <HeaderText>CC</HeaderText>
            </SortableColumnHeader>
            <Select
              size={'xs'}
              maxW={'60%'}
              mx={2}
              value={isEulog}
              onChange={e => {
                setRuleType(-1);
                setIsEulog(+e.currentTarget.value);
              }}
            >
              <option value={-1}>All</option>
              <option value={1}>Eulog</option>
              <option value={0}>Other</option>
            </Select>
          </Flex>
          <Flex flex={1}>
            <HeaderText>Rule</HeaderText>
            <Select
              size={'xs'}
              maxW={'60%'}
              mx={2}
              value={ruleType}
              onChange={e => {
                setIsEulog(-1);
                setRuleType(+e.currentTarget.value);
              }}
            >
              <option value={-1}>All</option>
              <option value={0}>Perpetual</option>
              <option value={1}>Manual</option>
              <option value={2}>Automatic</option>
            </Select>
          </Flex>
        </Flex>

        <Flex flex={1}>
          <CenterFlex>
            <HeaderText>Purchasable</HeaderText>
          </CenterFlex>
          <Center flex={1}>
            <SortableColumnHeader
              sortAscending={{
                name: sortBySalesforce.name,
                sortAscendingFn: sortBySalesforce,
              }}
            >
              <HeaderText>Salesforce</HeaderText>
            </SortableColumnHeader>
          </Center>
          <Center flex={1}>
            <SortableColumnHeader
              sortAscending={{
                name: sortByManual.name,
                sortAscendingFn: sortByManual,
              }}
            >
              <HeaderText>Manual</HeaderText>
            </SortableColumnHeader>
          </Center>
          <Center flex={1}>
            <SortableColumnHeader
              sortAscending={{
                name: sortByAutomatic.name,
                sortAscendingFn: sortByAutomatic,
              }}
            >
              <HeaderText>Automatic</HeaderText>
            </SortableColumnHeader>
          </Center>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductStocksHeader;
