import { AuthService } from './auth/AuthService';
import { CountriesService } from './countries/CountriesService';
import { InventoriesService } from './inventories/InventoriesService';
import { LogsService } from './logs-rule/LogsService';
import { OrdersService } from './orders/OrdersService';
import { ProductsService } from './products/ProductsService';
import { RetailStockService } from './retailstock/RetailStockService';
import { RulesService } from './rules/RulesService';

export const countriesService = new CountriesService();
export const inventoriesService = new InventoriesService();
export const productsService = new ProductsService();
export const rulesService = new RulesService();
export const authService = new AuthService();
export const ordersService = new OrdersService();
export const logsService = new LogsService();
export const retailStockService = new RetailStockService();
