import { DownloadIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import RetailStockPaginator from '../components/UI/RetailStockPaginator';
import { PaginationProvider, usePagination } from '../contexts/PaginationContext';
import { CountryDto } from '../core/countries/types';
import { RetailStockDto, StoreInfoDto } from '../core/retailstock/types';
import { countriesService, retailStockService } from '../core/services';

// The main component for results, which uses the pagination context
const RetailStockResults = ({
  country,
  store,
  sku,
  isLoading,
  error,
}: {
  country: string;
  store: string;
  sku: string;
  isLoading: boolean;
  error: string;
}) => {
  const [results, setResults] = useState<RetailStockDto[]>([]);
  const { pageIndex, pageSize, setPageCount } = usePagination();
  const [totalItems, setTotalItems] = useState(0);
  const [isExporting, setIsExporting] = useState(false);

  const exportToExcel = async () => {
    try {
      setIsExporting(true);
      const allData = await retailStockService.exportRetailStock(
        country,
        sku || undefined,
        store || undefined,
      );

      if (allData.length === 0) return;

      const exportData = allData.map(item => ({
        STORE: item.Store,
        NAME: item.Name,
        SKU: item.SKU,
        EAN: item.EAN,
        STOCK: item.Stock,
        COUNTRY: item.Country,
        INSERT_DATE: new Date(item.InsertDate).toLocaleString(),
      }));

      const XLSX = require('sheetjs-style');
      const FileSaver = require('file-saver');
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(wb, ws, 'RetailStock');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(
        data,
        `retail_stock_${country}_${store || 'all_stores'}_${new Date().toISOString().split('T')[0]}.xlsx`,
      );
    } catch (err) {
      console.error('Error exporting data:', err);
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    if (!country) return;

    const fetchData = async () => {
      try {
        const response = await retailStockService.getRetailStock(
          country,
          sku || undefined,
          store || undefined,
          pageIndex * pageSize,
          pageSize,
        );
        setResults(response.data);
        setTotalItems(response.total);
        setPageCount(Math.ceil(response.total / pageSize));
      } catch (err) {
        console.error('Error fetching retail stock data:', err);
      }
    };

    fetchData();
  }, [country, store, sku, pageIndex, pageSize, setPageCount]);

  if (error) {
    return (
      <Center mt={4}>
        <Text color="red.500">{error}</Text>
      </Center>
    );
  }

  return (
    <Box p={25} m={4} overflowX="auto" borderWidth="1px" borderRadius="lg">
      {results.length > 0 && (
        <>
          <Flex justifyContent="flex-end" mb={4}>
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="green"
              onClick={exportToExcel}
              size="sm"
              mr={4}
              isLoading={isExporting}
              loadingText="Exporting..."
            >
              Export
            </Button>
          </Flex>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Store</Th>
                <Th>Name</Th>
                <Th>SKU</Th>
                <Th>EAN</Th>
                <Th>Stock</Th>
                <Th>Country</Th>
                <Th>Insert Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {results.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.Store}</Td>
                  <Td>{item.Name}</Td>
                  <Td>{item.SKU}</Td>
                  <Td>{item.EAN}</Td>
                  <Td>{item.Stock}</Td>
                  <Td>{item.Country}</Td>
                  <Td>{new Date(item.InsertDate).toLocaleString()}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box mt={4}>
            <Text textAlign="right" mb={2}>
              Showing {results.length} of {totalItems} results
            </Text>
            <RetailStockPaginator />
          </Box>
        </>
      )}
      {results.length === 0 && !isLoading && (
        <Center>
          <Text>No results found</Text>
        </Center>
      )}
      {isLoading && (
        <Center>
          <Text>Loading...</Text>
        </Center>
      )}
    </Box>
  );
};

// The main component that wraps everything with the PaginationProvider
const RetailStock = () => {
  const [country, setCountry] = useState('');
  const [store, setStore] = useState('');
  const [availableStores, setAvailableStores] = useState<StoreInfoDto[]>([]);
  const [sku, setSku] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [error, setError] = useState('');
  const [totalResults, setTotalResults] = useState(0);
  const [searchTriggered, setSearchTriggered] = useState(false);

  const [countries, setCountries] = useState<CountryDto[]>([]);

  // Load available countries based on the user's role
  useEffect(() => {
    countriesService
      .fetchRetailStockCountries()
      .then(countriesData => {
        setCountries(countriesData);

        // If only one country is available, select it automatically
        if (countriesData.length === 1) {
          setCountry(countriesData[0].code);

          // Load stores for this single country
          loadStoresForCountry(countriesData[0].code);
        } else if (countriesData.length > 1) {
          // Select the first country by default if multiple are available
          setCountry(countriesData[0].code);
        }
      })
      .catch(err => {
        console.error('Error loading countries:', err);
        setError('Error loading available countries');
      });
  }, []);

  // Function to load stores for a country
  const loadStoresForCountry = (countryCode: string) => {
    if (!countryCode) return;

    setIsLoadingStores(true);
    setStore(''); // Reset the selected store

    retailStockService
      .getAvailableStores(countryCode)
      .then(stores => {
        setAvailableStores(stores);
        setIsLoadingStores(false);
      })
      .catch(err => {
        setError(`Error loading stores for ${countryCode}`);
        setAvailableStores([]);
        setIsLoadingStores(false);
        console.error(err);
      });
  };

  // Load stores when the country changes
  useEffect(() => {
    if (country) {
      loadStoresForCountry(country);
    }
  }, [country]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await retailStockService.getRetailStock(
        country,
        sku || undefined,
        store || undefined,
      );
      setTotalResults(response.total);
      setSearchTriggered(true);
    } catch (err) {
      setError('An error occurred during the search');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountry(e.target.value);
  };

  return (
    <Stack spacing={4}>
      <Grid templateColumns={{ base: '1fr', lg: '0.75fr 2.25fr' }} gap={1}>
        <GridItem>
          <Heading size="md" pl={5}>
            Filter
          </Heading>
          <Box p={25} m={4} borderWidth="1px" borderRadius="lg">
            <form onSubmit={handleSubmit}>
              <Stack spacing={4} mb={4}>
                <FormControl id="country">
                  <FormLabel>Country</FormLabel>
                  {countries.length === 1 ? (
                    // If only one country is available for the user, display a disabled Select
                    <Select name="country" isDisabled opacity="1 !important" value={country}>
                      <option value={countries[0].code}>
                        {`${countries[0].code} - ${countries[0].name}`}
                      </option>
                    </Select>
                  ) : (
                    // Otherwise, display the dropdown with all available countries
                    <Select
                      name="country"
                      value={country}
                      onChange={handleCountryChange}
                      placeholder="Select country"
                    >
                      {countries.map((countryItem, index) => (
                        <option key={index} value={countryItem.code}>
                          {`${countryItem.code} - ${countryItem.name}`}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>Store</FormLabel>
                  <Select
                    value={store}
                    onChange={e => setStore(e.target.value)}
                    placeholder="All stores"
                    isDisabled={isLoadingStores || availableStores.length === 0}
                  >
                    {availableStores.map(storeInfo => (
                      <option key={storeInfo.store} value={storeInfo.store}>
                        {storeInfo.store} - {storeInfo.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>SKU</FormLabel>
                  <Input
                    value={sku}
                    onChange={e => setSku(e.target.value)}
                    placeholder="Enter SKU (optional)"
                  />
                </FormControl>
              </Stack>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isLoading}
                isDisabled={!country}
                width={{ base: '100%', md: 'auto' }}
              >
                Search
              </Button>
            </form>
          </Box>
        </GridItem>
        <GridItem>
          <Heading size="md" pl={5}>
            Retail Stock {totalResults > 0 && `(${totalResults} results)`}
          </Heading>
          {searchTriggered && (
            <PaginationProvider total={totalResults}>
              <RetailStockResults
                country={country}
                store={store}
                sku={sku}
                isLoading={isLoading}
                error={error}
              />
            </PaginationProvider>
          )}
          {!searchTriggered && (
            <Box p={25} m={4} borderWidth="1px" borderRadius="lg">
              <Center>
                <Text>Please search to see results</Text>
              </Center>
            </Box>
          )}
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default RetailStock;
