import axios from 'axios';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiConfig } from './authConfig';

interface AuthContextType {
  isAuthenticated: boolean;
  user: any | null;
  loading: boolean;
  error: string | null;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Configure axios globally to include credentials
  useEffect(() => {
    axios.defaults.withCredentials = true;

    // Add a response interceptor to handle 401 errors
    axios.interceptors.response.use(
      response => response,
      error => {
        // If we get a 401 Unauthorized error, redirect to login
        if (error.response && error.response.status === 401) {
          setIsAuthenticated(false);
          setUser(null);
          navigate('/login', { state: { from: location } });
        }
        return Promise.reject(error);
      },
    );
  }, [navigate, location]);

  // Initialize authentication state
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(`${apiConfig.baseUrl}/auth/status`, {
          withCredentials: true,
        });

        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
          setUser(response.data.user);

          // If we're on the login page but we're authenticated, redirect to home
          if (location.pathname === '/login') {
            // Get the redirect location from state, or default to home
            const from = (location.state as any)?.from?.pathname || '/';
            navigate(from, { replace: true });
          }
        } else {
          setIsAuthenticated(false);
          setUser(null);

          // Redirect to login if not authenticated and not already on the login page
          if (location.pathname !== '/login') {
            navigate('/login', { state: { from: location } });
          }
        }
        setLoading(false);
      } catch (err) {
        console.error('Error checking auth status:', err);
        setError('Failed to check authentication status');
        setIsAuthenticated(false);
        setUser(null);
        setLoading(false);

        // Redirect to login on error if not already on the login page
        if (location.pathname !== '/login') {
          navigate('/login', { state: { from: location } });
        }
      }
    };

    checkAuthStatus();
  }, [navigate, location]);

  const login = () => {
    window.location.href = `${apiConfig.baseUrl}${apiConfig.loginEndpoint}`;
  };

  const logout = async () => {
    try {
      // First clear the local auth state
      setIsAuthenticated(false);
      setUser(null);

      // Then redirect to the backend logout endpoint
      window.location.href = `${apiConfig.baseUrl}${apiConfig.logoutEndpoint}`;
    } catch (error) {
      console.error('Logout failed:', error);
      // If logout fails, still redirect to login
      navigate('/login');
    }
  };

  // Get user info if authenticated but no user data
  useEffect(() => {
    if (isAuthenticated && !user) {
      const getUserInfo = async () => {
        try {
          const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.userInfoEndpoint}`, {
            withCredentials: true,
          });
          setUser(response.data);
        } catch (err) {
          console.error('Error fetching user info:', err);
          setError('Failed to fetch user information');
        }
      };

      getUserInfo();
    }
  }, [isAuthenticated, user]);

  const value = {
    isAuthenticated,
    user,
    loading,
    error,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
