import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthProvider } from '../auth/AuthProvider';
import ProtectedRoute from '../auth/ProtectedRoute';
import Layout from '../components/UI/Layout';
import Login from './Login/Login';
import RetailStock from './RetailStock';
import Stock from './Stock';
import Unauthorized from './Unauthorized/Unauthorized';

const environment = process.env.REACT_APP_LOCAL_ENV ? process.env.REACT_APP_LOCAL_ENV + ' - ' : '';

const Pages = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          {/* Protected routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout title={`${environment} JOHNY`}>
                  <Stock />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/retail-stock"
            element={
              <ProtectedRoute>
                <Layout title={`${environment} JOHNY`}>
                  <RetailStock />
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default Pages;
