import { Button, Center, SkeletonCircle, VisuallyHiddenInput, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { FormEvent, useState } from 'react';
import { ErrorAlert, SuccessAlert } from '../../common/Alerts';
import RuleType from '../../common/RuleType';
import { ApiError } from '../../core/api/types';
import { CountryDto } from '../../core/countries/types';
import { ProductDto } from '../../core/products/types';
import { UpdateRuleDtoBuilder } from '../../core/rules/builders';
import { UpdateRuleDto } from '../../core/rules/types';
import { rulesService } from '../../core/services';
import { RuleLifetimeControl, RuleQuantityControl, RuleTypeControl } from './RuleControls';

interface RuleEditorProps {
  product: ProductDto;
  country: CountryDto;
}
const RuleEditor = ({ product, country, ...rest }: RuleEditorProps) => {
  /**
   * Constructs the initial DTO for the RuleEditor component.
   * If the product has a rule, it constructs the DTO using the UpdateRuleDtoBuilder.fromRuleDto method.
   * Otherwise, it constructs the DTO using the UpdateRuleDtoBuilder.from method.
   * @param product - The product object.
   * @param country - The country object.
   * @returns The initial DTO for the RuleEditor component.
   */
  const initialDto = product.rule
    ? UpdateRuleDtoBuilder.fromRuleDto(product.rule)
    : UpdateRuleDtoBuilder.from(
        product.id,
        country.code,
        product.is_perpetual ? RuleType.Perpetual.name : RuleType.Automatic.name,
        undefined,
        product.sf_quantity,
      );

  const [updatedRule, setUpdatedRule] = useState(initialDto);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<ApiError>();

  /**
   * Handles the change event when an option is selected.
   * @param nextValue - The new value selected.
   */
  const handleOptionChanged = (nextValue: string) => {
    setUpdatedRule((prev: UpdateRuleDto) => {
      return {
        ...prev,
        type: RuleType.fromName(nextValue).name ?? '',
      };
    });
  };

  /**
   * Handles the change event for the lifetime input field.
   * Converts the input value to UTC and updates the rule's lifetime.
   * @param event - The change event object.
   */
  const handleLifetimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const lifetime = moment(event.target.value).utc().toISOString();
    setUpdatedRule((prev: UpdateRuleDto) => {
      return { ...prev, lifetime: lifetime };
    });
  };

  /**
   * Handles the change event for the quantity input field.
   * Updates the rule with the new quantity value.
   *
   * @param value - The new value for the quantity.
   */
  const handleQuantityChange = (value: string) => {
    setUpdatedRule((prev: UpdateRuleDto) => {
      return {
        ...prev,
        quantity: +value,
      };
    });
  };

  /**
   * Handles the form submission for the RuleEditor component.
   *
   * @param e - The form event.
   */
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setIsSuccess(false);
    setError(undefined);
    rulesService
      .updateRule(product.rule?.id ?? 0, updatedRule)
      .then(res => {
        setUpdatedRule(UpdateRuleDtoBuilder.fromRuleDto(res));
      })
      .then(() => setIsSuccess(true))
      .catch(setError)
      .finally(() => setIsLoading(false));
  };

  return isLoading ? (
    <Center h="100%">
      <SkeletonCircle size={'50'} startColor={'whiteAlpha.100'} endColor={'yellow.400'} />
    </Center>
  ) : (
    <>
      {isSuccess && (
        <SuccessAlert
          title={`Rule successfully updated`}
          description={`Rule for ${updatedRule.sku} - ${updatedRule.countryCode} has been set to ${updatedRule.type}.`}
          mb={4}
        />
      )}
      {error && <ErrorAlert error={error} />}
      <form onSubmit={handleSubmit}>
        <VisuallyHiddenInput name="sku" defaultValue={updatedRule.sku} />
        <VisuallyHiddenInput name="countryCode" defaultValue={updatedRule.countryCode} />
        <VStack {...rest}>
          <RuleTypeControl
            ruleType={RuleType.fromName(updatedRule.type)}
            onChange={handleOptionChanged}
            automaticQuantiy={product.automatic_quantity}
            manualQuantity={product.sf_quantity}
            updatedDate={product.rule?.updatedAt}
            author={product.rule?.author}
            sku={product.id}
            country={country.code}
          />
          <RuleLifetimeControl
            ruleType={RuleType.fromName(updatedRule.type)}
            onChange={handleLifetimeChange}
            lifetime={updatedRule.lifetime}
          />
          <RuleQuantityControl
            ruleType={RuleType.fromName(updatedRule.type)}
            onChange={handleQuantityChange}
            quantity={updatedRule.quantity}
          />
          <Button type="submit" isLoading={isLoading}>
            Save
          </Button>
        </VStack>
      </form>
    </>
  );
};

export default RuleEditor;
