import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      /* Swatch */
      @font-face {
				font-family: 'Swatch CT Extended';
				src: url('fonts/swatch_ct_extended.woff2') format('woff2');
				font-weight: 100;
				font-style: normal;
			}
      `}
  />
);

export default Fonts;
