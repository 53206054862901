import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  VStack,
} from '@chakra-ui/react';
import {
  ActionMeta,
  AsyncSelect,
  GroupBase,
  MultiValue,
  OptionBase,
  OptionsOrGroups,
  SelectInstance,
} from 'chakra-react-select';
import moment from 'moment';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorAlert, SuccessAlert } from '../../common/Alerts';
import RuleType from '../../common/RuleType';
import { ApiError } from '../../core/api/types';
import { CountryDto } from '../../core/countries/types';
import { UpdateRuleDto } from '../../core/rules/types';
import { countriesService, rulesService } from '../../core/services';
import AddRemoveButton from '../misc/AddRemoveButton';
import { BoxField, WrapField } from '../misc/Fields';
import { RuleLifetimeControl, RuleQuantityControl, RuleTypeControl } from './RuleControls';

interface CountryOption extends OptionBase {
  label: string;
  value: string;
}

type RulesEditorProps = {
  selectedSkus: string[];
  selectedCountries?: string[];
  isOpen: boolean;
  onClose: () => void;
};
const RulesEditor: FC<RulesEditorProps> = ({
  selectedSkus,
  selectedCountries: preselectedCountries = [],
  isOpen,
  onClose,
}) => {
  const initialRule: UpdateRuleDto = {
    sku: '',
    countryCode: '',
    type: RuleType.Perpetual.name,
    lifetime: undefined,
    quantity: 0,
  };
  const [updatedRule, setUpdatedRule] = useState<UpdateRuleDto>(initialRule);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<ApiError>();
  const [countries, setCountries] = useState<CountryDto[]>([]);
  const [selectedCountries, setSelectedCountries] = useState([
    ...preselectedCountries.filter((value, index, self) => self.indexOf(value) === index),
  ]);
  const countriesSelectedRef =
    useRef<SelectInstance<CountryOption, true, GroupBase<CountryOption>>>(null);

  useEffect(() => {
    countriesService.fetchCountries().then(c => setCountries(c));
  }, []);

  const hasSelectedCountries = useMemo(
    () => isOpen && preselectedCountries.length > 0,
    [isOpen, preselectedCountries.length],
  );

  useEffect(() => {
    setSelectedCountries(
      preselectedCountries.filter((value, index, self) => self.indexOf(value) === index),
    );
  }, [hasSelectedCountries, preselectedCountries]);

  useEffect(() => {
    setIsLoading(false);
    setIsSuccess(false);
    setError(undefined);
  }, [selectedSkus, selectedCountries]);

  const loadCountryOptions = (
    inputValue: string,
    callback: (options: OptionsOrGroups<CountryOption, GroupBase<CountryOption>>) => void,
  ) => {
    setTimeout(async () => {
      const value = inputValue.toUpperCase();
      callback(
        countries
          .map(c => {
            return {
              value: c.code,
              label: `${c.code} - ${c.name}`,
            } as CountryOption;
          })
          .filter(o => o.label.toUpperCase().match(`${value}`)),
      );
    }, 300);
  };

  const handleCountriesChange = (newValue: MultiValue<CountryOption>) => {
    setSelectedCountries(newValue.map(o => o.value));
  };

  const updateSelectedCountryOptions = (options: string[]) => {
    countriesSelectedRef.current?.onChange(
      options.map(c => {
        return {
          label: c,
          value: c,
        } as CountryOption;
      }) as MultiValue<CountryOption>,
      {} as ActionMeta<CountryOption>,
    );
  };

  const handleRuleTypeChanged = (nextValue: string) => {
    setUpdatedRule((prev: UpdateRuleDto) => {
      return {
        ...prev,
        type: RuleType.fromName(nextValue).name ?? '',
      };
    });
  };

  const handleLifetimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const lifetime = moment(event.target.value).utc().toISOString();
    setUpdatedRule((prev: UpdateRuleDto) => {
      return { ...prev, lifetime: lifetime };
    });
  };

  const handleQuantityChange = (value: string) => {
    setUpdatedRule((prev: UpdateRuleDto) => {
      return {
        ...prev,
        quantity: +value,
      };
    });
  };

  const handleOnSubmit = () => {
    setIsLoading(true);
    setIsSuccess(false);
    setError(undefined);
    rulesService
      .updateRules(
        selectedCountries.flatMap(c => {
          return selectedSkus.map(s => {
            return {
              ...updatedRule,
              sku: s,
              countryCode: c,
            } as UpdateRuleDto;
          });
        }),
      )
      .then(() => setIsSuccess(true))
      .catch(setError)
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal closeOnOverlayClick={!isLoading} isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Apply a rule...</ModalHeader>
        <ModalBody>
          <VStack>
            {isSuccess && (
              <SuccessAlert
                title={`Rules successfully updated`}
                description={`Rules for ${updatedRule.sku} has been set to ${updatedRule.type}.`}
              />
            )}
            {error && <ErrorAlert error={error} />}
            <FormControl w="100%">
              <VStack w="100%">
                <WrapField name="SKU" gridTemplateColumns="1fr 2fr" w="100%">
                  {selectedSkus.map(s => {
                    return <Tag key={s}>{s}</Tag>;
                  })}
                </WrapField>
                {hasSelectedCountries ? (
                  <WrapField name="Countries" gridTemplateColumns="1fr 2fr" w="100%">
                    {selectedCountries.map(cc => {
                      return (
                        <Tag key={cc} colorScheme="yellow">
                          {cc}
                        </Tag>
                      );
                    })}
                  </WrapField>
                ) : (
                  <BoxField name="Countries" gridTemplateColumns="1fr 2fr" w="100%">
                    <Flex direction={'column'} gap={1}>
                      <AsyncSelect<CountryOption, true, GroupBase<CountryOption>>
                        ref={countriesSelectedRef}
                        isMulti
                        colorScheme="yellow"
                        cacheOptions={true}
                        loadOptions={loadCountryOptions}
                        onChange={handleCountriesChange}
                      />
                      <Flex gap={1}>
                        <AddRemoveButton
                          isAdd={
                            countries.map(c => c.code).filter(c => selectedCountries.includes(c))
                              .length === 0
                          }
                          toggle={add =>
                            updateSelectedCountryOptions(
                              add
                                ? [
                                    ...selectedCountries,
                                    ...countries
                                      .map(c => c.code)
                                      .filter(c => !selectedCountries.includes(c)),
                                  ]
                                : [],
                            )
                          }
                        >
                          {'All'}
                        </AddRemoveButton>
                        <AddRemoveButton
                          isAdd={
                            countries
                              .filter(c => c.isEulog)
                              .map(c => c.code)
                              .filter(c => selectedCountries.includes(c)).length === 0
                          }
                          toggle={add => {
                            const eulogs = countries.filter(c => c.isEulog).map(c => c.code);
                            updateSelectedCountryOptions(
                              add
                                ? [
                                    ...selectedCountries,
                                    ...eulogs.filter(c => !selectedCountries.includes(c)),
                                  ]
                                : [...selectedCountries.filter(c => !eulogs.includes(c))],
                            );
                          }}
                        >
                          {'Eulog'}
                        </AddRemoveButton>
                        <AddRemoveButton
                          isAdd={
                            countries
                              .filter(c => c.isNordic)
                              .map(c => c.code)
                              .filter(c => selectedCountries.includes(c)).length === 0
                          }
                          toggle={add => {
                            const nordics = countries.filter(c => c.isNordic).map(c => c.code);
                            updateSelectedCountryOptions(
                              add
                                ? [
                                    ...selectedCountries,
                                    ...nordics.filter(c => !selectedCountries.includes(c)),
                                  ]
                                : [...selectedCountries.filter(c => !nordics.includes(c))],
                            );
                          }}
                        >
                          {'Nordics'}
                        </AddRemoveButton>
                        <AddRemoveButton
                          isAdd={
                            countries
                              .filter(c => !c.isEulog)
                              .map(c => c.code)
                              .filter(c => selectedCountries.includes(c)).length === 0
                          }
                          toggle={add => {
                            const notEulogs = countries.filter(c => !c.isEulog).map(c => c.code);
                            updateSelectedCountryOptions(
                              add
                                ? [
                                    ...selectedCountries,
                                    ...notEulogs.filter(c => !selectedCountries.includes(c)),
                                  ]
                                : [...selectedCountries.filter(c => !notEulogs.includes(c))],
                            );
                          }}
                        >
                          {'Other'}
                        </AddRemoveButton>
                      </Flex>
                    </Flex>
                  </BoxField>
                )}
                <RuleTypeControl
                  ruleType={RuleType.fromName(updatedRule.type)}
                  onChange={handleRuleTypeChanged}
                  automaticQuantiy={0}
                  manualQuantity={0}
                />
                <RuleLifetimeControl
                  ruleType={RuleType.fromName(updatedRule.type)}
                  onChange={handleLifetimeChange}
                  lifetime={updatedRule.lifetime}
                />
                <RuleQuantityControl
                  ruleType={RuleType.fromName(updatedRule.type)}
                  onChange={handleQuantityChange}
                  quantity={updatedRule.quantity}
                />
              </VStack>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            colorScheme="blue"
            mr={3}
            isLoading={isLoading}
            onClick={handleOnSubmit}
          >
            Save
          </Button>
          <Button isDisabled={isLoading} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RulesEditor;
