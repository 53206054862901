import moment from 'moment';
import { OrdersDto } from '../../core/orders/types';

type ExportProduct = {
  DATE: string;
  COUNTRY: string;
  CALLEO_ID: string;
  STATUS: string;
  SKU: string;
  QUANTITY: number;
};

const formatDate = (dateString: string) => {
  return moment(dateString).format('DD.MM.YYYY HH:mm:ss');
};

const formatNumberToDecimal = (number: number) => {
  return parseFloat(Number(number).toFixed(2));
};

const statusMapping: { [key: number]: string } = {
  1: 'Ready to process',
  2: 'Processing Order',
  3: 'Order Processed',
  4: 'Order Cancelled',
  5: 'Order Partially Processed',
  6: 'Order Exported',
  7: 'Idoc shipment received',
  8: 'Order Created',
  9: 'Order Delivered',
  10: 'Delivery in Progress',
  13: 'Order Shipped to the store',
  14: 'Order in Store',
  15: 'Store order: ready to process',
  16: 'Store order: validated by staff',
  17: 'Order picked up by the customer',
  18: 'Order picked up by the logistic',
  19: 'Order settled',
  21: 'Issue with delivery',
  22: 'Order imported in Cegid',
  23: 'Store order: waiting customer pick up',
  24: 'Order waiting Shipment',
};

export const ConvertOrdersToExport = (o: OrdersDto): ExportProduct => {
  return {
    DATE: formatDate(o.shop_created_at),
    COUNTRY: o.country,
    CALLEO_ID: o.ext_id,
    STATUS: statusMapping[o.status] || 'Unknown Status',
    SKU: o.sku,
    QUANTITY: formatNumberToDecimal(o.quantity),
  };
};
