import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useColorMode,
} from '@chakra-ui/react';
import moment from 'moment';
import { FC, useCallback, useMemo, useState } from 'react';
import { exportToExcel } from '../../common/export';
import { ConvertOrdersToExport } from '../../common/orders/export';
import { OrdersDto } from '../../core/orders/types';
import { ordersService } from '../../core/services';

type OrdersHistoryProps = {
  sku?: string | string[];
  country?: string;
  isOpen: boolean;
  onClose: () => void;
};

type ViewType = 'sku' | 'country';

const OrdersHistory: FC<OrdersHistoryProps> = ({ sku, isOpen, country, onClose }) => {
  const initialStartDate = useMemo(() => moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), []);
  const initialEndDate = useMemo(() => moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'), []);

  const [viewType, setViewType] = useState<ViewType>('country');

  const [state, setState] = useState({
    isLoading: false,
    orders: [] as OrdersDto[],
    startDate: initialStartDate,
    endDate: initialEndDate,
    error: false,
    errorMessage: '',
  });

  const { isLoading, orders, startDate, endDate, error, errorMessage } = state;

  const quantitiesByCountry = useMemo(
    () =>
      orders.reduce(
        (acc, order) => {
          const quantity = Number(order.quantity);
          acc[order.country] = (acc[order.country] || 0) + quantity;
          return acc;
        },
        {} as Record<string, number>,
      ),
    [orders],
  );

  const quantitiesByCountryAndSku = useMemo(() => {
    return orders.reduce(
      (acc, order) => {
        if (!acc[order.country]) {
          acc[order.country] = {};
        }
        if (!acc[order.country][order.sku]) {
          acc[order.country][order.sku] = 0;
        }
        acc[order.country][order.sku] += Number(order.quantity);
        return acc;
      },
      {} as Record<string, Record<string, number>>,
    );
  }, [orders]);

  const searchOrders = useCallback(() => {
    setState(prevState => ({ ...prevState, isLoading: true, error: false }));

    ordersService
      .searchOrders({
        sku: Array.isArray(sku) ? sku.join(',') : (sku ?? ''),
        start: startDate,
        end: endDate,
        country: country ?? '',
      })
      .then(data => {
        setState(prevState => ({ ...prevState, orders: data, isLoading: false }));
      })
      .catch(error => {
        setState(prevState => ({
          ...prevState,
          error: true,
          errorMessage: error.status,
          isLoading: false,
        }));
      });
  }, [sku, startDate, endDate, country]);

  const getExportOrders = useCallback(() => {
    return orders.map(o => ConvertOrdersToExport(o));
  }, [orders]);

  const exportToExcelAction = useCallback(() => {
    const exportProducts = getExportOrders();
    exportToExcel(exportProducts, 'CALLEO_ORDERS_' + (Array.isArray(sku) ? sku.join('_') : sku));
  }, [getExportOrders, sku]);

  const clearOrdersOnClose = useCallback(() => {
    setState({
      isLoading: false,
      orders: [],
      startDate: initialStartDate,
      endDate: initialEndDate,
      error: false,
      errorMessage: '',
    });
    onClose();
  }, [onClose, initialEndDate, initialStartDate]);

  const handleViewType = useCallback((type: ViewType) => {
    setViewType(type);
  }, []);

  const { colorMode } = useColorMode();

  const isDarkMode = colorMode === 'dark';

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={clearOrdersOnClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`Calleo orders history ${country ? 'in ' : ''}`}
          {country && <span style={{ color: '#9ae6b4' }}>{country}</span>}
          {` for : `}
          <UnorderedList styleType="'- '" style={{ fontSize: 'medium', color: '#90cdf4' }} mt={2}>
            <Grid templateColumns="repeat(4, 1fr)" gap={1}>
              {sku && Array.isArray(sku) ? (
                <>
                  {sku.map((skus, index) => (
                    <ListItem key={index}>{skus}</ListItem>
                  ))}
                </>
              ) : (
                <ListItem>{sku}</ListItem>
              )}
            </Grid>
          </UnorderedList>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack justify={'space-between'}>
            <FormControl w="45%">
              <FormLabel htmlFor="start">Start date</FormLabel>
              <Input
                id="start"
                name="start"
                type="date"
                defaultValue={moment().format('YYYY-MM-DD')}
                onChange={e => setState(prevState => ({ ...prevState, startDate: e.target.value }))}
              />
            </FormControl>
            <FormControl w="45%">
              <FormLabel htmlFor="end">End date</FormLabel>
              <Input
                id="end"
                name="end"
                type="date"
                defaultValue={moment().format('YYYY-MM-DD')}
                onChange={e => setState(prevState => ({ ...prevState, endDate: e.target.value }))}
              />
            </FormControl>
          </HStack>
          <Center my={6}>
            <HStack>
              <Button
                isDisabled={isLoading}
                onClick={searchOrders}
                colorScheme="blue"
                size={'sm'}
                leftIcon={<SearchIcon />}
                fontWeight={'normal'}
              >
                Search
              </Button>
              {orders.length !== 0 && (
                <Button
                  isDisabled={isLoading}
                  onClick={exportToExcelAction}
                  colorScheme="green"
                  size={'sm'}
                  leftIcon={<DownloadIcon />}
                  fontWeight={'normal'}
                >
                  Export
                </Button>
              )}
            </HStack>
          </Center>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}

          {isLoading ? (
            <Center mt={6}>
              <Spinner
                size={'xl'}
                emptyColor="whiteAlpha.200"
                color="yellow.500"
                thickness="4px"
                speed="0.65s"
              />
            </Center>
          ) : (
            orders.length !== 0 && (
              <>
                <Stack direction="row" mb={4}>
                  <Checkbox
                    isChecked={viewType === ('country' as ViewType)}
                    onChange={() => handleViewType('country')}
                  >
                    List by Country
                  </Checkbox>
                  <Checkbox
                    isChecked={viewType === ('sku' as ViewType)}
                    onChange={() => handleViewType('sku')}
                  >
                    List by SKU
                  </Checkbox>
                </Stack>
                {viewType === 'country' && (
                  <>
                    <Box overflowY="auto" maxHeight="80svh" mt={10}>
                      <Table variant="simple" colorScheme="whiteAlpha">
                        <Thead>
                          <Tr>
                            <Th
                              position="sticky"
                              top={0}
                              left={0}
                              zIndex={3}
                              background={'gray.800'}
                              color={'white'}
                              fontSize="small"
                            >
                              Country
                            </Th>
                            {sku && Array.isArray(sku) ? (
                              <>
                                {sku.map((skus, index) => (
                                  <Th
                                    key={index}
                                    position="sticky"
                                    top={0}
                                    zIndex={2}
                                    background={'gray.700'}
                                    textAlign="center"
                                    fontSize="sm"
                                    color={isDarkMode ? 'gray.400' : 'white'}
                                  >
                                    {skus}
                                  </Th>
                                ))}
                              </>
                            ) : (
                              <Th
                                position="sticky"
                                top={0}
                                zIndex={2}
                                background={'gray.700'}
                                textAlign="center"
                                fontSize="sm"
                                color={isDarkMode ? 'gray.400' : 'white'}
                              >
                                {sku}
                              </Th>
                            )}
                            <Th
                              position="sticky"
                              top={0}
                              right={0}
                              zIndex={3}
                              background={'gray.800'}
                              textAlign={'center'}
                              color={'white'}
                              fontSize="small"
                            >
                              Total by Country
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {Object.entries(quantitiesByCountryAndSku).map(
                            ([country, skus], index) => (
                              <Tr key={index}>
                                <Td
                                  position="sticky"
                                  left={0}
                                  zIndex={2}
                                  background={'gray.600'}
                                  textAlign="left"
                                  fontSize="large"
                                  color={isDarkMode ? 'gray.400' : 'white'}
                                >
                                  {country}
                                </Td>
                                {sku && Array.isArray(sku) ? (
                                  <>
                                    {sku.map((skuItem, index) => (
                                      <Td key={index} textAlign="center">
                                        {skus[skuItem] > 0 ? skus[skuItem] : '-'}
                                      </Td>
                                    ))}
                                  </>
                                ) : (
                                  <Td textAlign="center">
                                    {sku && skus[sku] > 0 ? skus[sku] : '-'}
                                  </Td>
                                )}
                                <Td
                                  isNumeric
                                  position="sticky"
                                  right={0}
                                  zIndex={2}
                                  background={'gray.600'}
                                  textAlign="right"
                                  fontSize="large"
                                  color={'white'}
                                >
                                  {Object.values(skus).reduce(
                                    (total, current) => total + current,
                                    0,
                                  )}
                                </Td>
                              </Tr>
                            ),
                          )}
                        </Tbody>
                        <Tfoot>
                          <Tr>
                            <Th
                              position="sticky"
                              left={0}
                              bottom={0}
                              background={'gray.800'}
                              zIndex={3}
                              fontSize={'small'}
                              color={'white'}
                            >
                              Total by SKU
                            </Th>
                            {sku &&
                              Array.isArray(sku) &&
                              sku.map(skuItem => (
                                <Th
                                  position="sticky"
                                  bottom={0}
                                  background={'gray.600'}
                                  fontSize={'md'}
                                  color={'white'}
                                  textAlign={'center'}
                                >
                                  {Object.values(quantitiesByCountryAndSku).reduce(
                                    (total, countrySkus) => total + (countrySkus[skuItem] || 0),
                                    0,
                                  )}
                                </Th>
                              ))}
                            <Th
                              position="sticky"
                              bottom={0}
                              right={0}
                              fontSize={'lg'}
                              textAlign="right"
                              color={'white'}
                              background={'gray.900'}
                              zIndex={3}
                            >
                              {Object.values(quantitiesByCountry).reduce(
                                (total, current) => total + current,
                                0,
                              )}
                            </Th>
                          </Tr>
                        </Tfoot>
                      </Table>
                    </Box>
                    <Text fontSize={'sm'} textAlign={'center'} my={5}>
                      Calleo orders between {moment(startDate).format('YYYY-MM-DD HH:mm')} and{' '}
                      {moment(endDate).format('YYYY-MM-DD HH:mm')}
                    </Text>
                  </>
                )}
                {viewType === 'sku' && (
                  <>
                    <Box overflowY="auto" maxHeight="80svh" mt={10}>
                      <Table variant="simple" colorScheme="whiteAlpha">
                        <Thead>
                          <Tr>
                            <Th
                              position="sticky"
                              top={0}
                              left={0}
                              zIndex={3}
                              background={'gray.800'}
                              color={'white'}
                              fontSize="small"
                            >
                              SKU
                            </Th>
                            {Object.keys(quantitiesByCountryAndSku).map((country, index) => (
                              <Th
                                key={index}
                                position="sticky"
                                top={0}
                                zIndex={2}
                                background={'gray.700'}
                                textAlign="center"
                                fontSize="sm"
                                color={isDarkMode ? 'gray.400' : 'white'}
                              >
                                {country}
                              </Th>
                            ))}
                            <Th
                              position="sticky"
                              top={0}
                              right={0}
                              zIndex={3}
                              background={'gray.800'}
                              textAlign="center"
                              color={'white'}
                              fontSize="small"
                            >
                              Total by SKU
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {sku &&
                            Array.isArray(sku) &&
                            sku.map((skuItem, skuIndex) => (
                              <Tr key={skuIndex}>
                                <Td
                                  position="sticky"
                                  left={0}
                                  zIndex={2}
                                  background={'gray.600'}
                                  textAlign="left"
                                  fontSize="large"
                                  color={isDarkMode ? 'gray.400' : 'white'}
                                >
                                  {skuItem}
                                </Td>
                                {Object.keys(quantitiesByCountryAndSku).map(
                                  (country, countryIndex) => (
                                    <Td key={countryIndex} textAlign="center">
                                      {quantitiesByCountryAndSku[country][skuItem] > 0
                                        ? quantitiesByCountryAndSku[country][skuItem]
                                        : '-'}
                                    </Td>
                                  ),
                                )}
                                <Td
                                  isNumeric
                                  position="sticky"
                                  right={0}
                                  zIndex={2}
                                  background={'gray.600'}
                                  textAlign="right"
                                  fontSize="large"
                                  color={isDarkMode ? '' : 'white'}
                                >
                                  {Object.values(quantitiesByCountryAndSku).reduce(
                                    (total, countrySkus) => total + (countrySkus[skuItem] || 0),
                                    0,
                                  )}
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                        <Tfoot>
                          <Tr>
                            <Th
                              position="sticky"
                              left={0}
                              bottom={0}
                              background={'gray.800'}
                              zIndex={3}
                              fontSize={'small'}
                              color={'white'}
                            >
                              Total by Country
                            </Th>
                            {Object.entries(quantitiesByCountryAndSku).map(([country, skus]) => (
                              <Th
                                position="sticky"
                                bottom={0}
                                background={'gray.600'}
                                fontSize={'md'}
                                color={'white'}
                                textAlign={'center'}
                              >
                                {Object.values(skus).reduce((total, current) => total + current, 0)}
                              </Th>
                            ))}
                            <Th
                              position="sticky"
                              bottom={0}
                              right={0}
                              fontSize={'lg'}
                              textAlign="right"
                              color={'white'}
                              background={'gray.900'}
                              zIndex={3}
                            >
                              {Object.values(quantitiesByCountry).reduce(
                                (total, current) => total + current,
                                0,
                              )}
                            </Th>
                          </Tr>
                        </Tfoot>
                      </Table>
                    </Box>
                    <Text fontSize={'sm'} textAlign={'center'} my={5}>
                      Calleo orders between {moment(startDate).format('YYYY-MM-DD HH:mm')} and{' '}
                      {moment(endDate).format('YYYY-MM-DD HH:mm')}
                    </Text>
                  </>
                )}
              </>
            )
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OrdersHistory;
