import ApiClient from '../api/ApiClient';
import { fetchRelatedLogDto, LogsDto } from './types';

export class LogsService {
  private readonly apiBase: string;
  private readonly apiClient: ApiClient;

  constructor() {
    this.apiBase = 'logs';
    this.apiClient = new ApiClient();
  }

  async fetchRelated(query: fetchRelatedLogDto): Promise<LogsDto | LogsDto[]> {
    return this.apiClient.get(`${this.apiBase}`, { params: query });
  }
}
