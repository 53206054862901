import { noop } from 'lodash';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type ProductContextType = {
  sku: string;
  setSku: Dispatch<SetStateAction<string>>;
  inventory?: string;
  setInventory: Dispatch<SetStateAction<string | undefined>>;
};
const defaultProductContext: ProductContextType = {
  sku: '',
  setSku: noop,
  inventory: undefined,
  setInventory: noop,
};

const ProductContext = createContext<typeof defaultProductContext>(
  defaultProductContext,
);

type ProductProviderProps = {
  children: ReactNode | ReactNode[];
};
export const ProductProvider = ({ children }: ProductProviderProps) => {
  const [sku, setSku] = useState<string>(defaultProductContext.sku);
  const [inventory, setInventory] = useState<string | undefined>(
    defaultProductContext.inventory,
  );

  return (
    <ProductContext.Provider
      value={{
        sku: sku,
        setSku: setSku,
        inventory: inventory,
        setInventory: setInventory,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export function useProduct() {
  return useContext(ProductContext);
}
