import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import foundations from './foundations';
import styles from './styles';

const pulseKeyframes = keyframes`
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
`;

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'dark',
  cssVarPrefix: 'chakra',
};

const overrides = {
  styles,
  ...foundations,
  config,
  layerStyles: {
    pulse: {
      animation: `${pulseKeyframes} 2s infinite`,
      display: 'inline-block',
    },
  },
};

export default extendTheme(overrides);
