import { Flex, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';

type ResultTableRowProps = {
  children?: React.ReactNode
}
const ResultTableRow: FC<ResultTableRowProps> = ({ children }) => {
  return (
    <Flex
      w={'100%'}
      borderBottom={'solid 1px'}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      py={1}
      _hover={{
        bgColor: useColorModeValue('yellow.100', 'yellow.400'),
      }}
    >
      {children}
    </Flex>
  );
};

export default ResultTableRow;
