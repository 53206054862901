import {
  HStack,
  Button,
  Spacer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsRight,
} from 'react-icons/fi';
import { usePagination } from '../../contexts/PaginationContext';
import useDebounce from '../../hooks/useDebounce';

const Paginator = () => {
  const {
    pageIndex,
    pageSize,
    setPageSize,
    pageCount,
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    previousPage,
  } = usePagination();

  const [pageSizeValue, setPageSizeValue] = useState(pageSize);
  const debouncedPageSize = useDebounce(pageSizeValue, 400);

  useEffect(() => {
    setPageSize(debouncedPageSize);
  }, [debouncedPageSize, setPageSize]);

  return (
    <HStack>
      <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        <FiChevronsLeft aria-label="first page" />
      </Button>
      <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <FiChevronLeft aria-label="previous page" />
      </Button>
      <Spacer />
      <Text>{`Page ${pageIndex + 1} of ${pageCount}`}</Text>
      <NumberInput
        value={pageSizeValue}
        onChange={(value) => {
          setPageSizeValue(+value);
        }}
        step={10}
        min={10}
        max={200}
        size="sm"
        maxW={20}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Spacer />
      <Button onClick={() => nextPage()} disabled={!canNextPage}>
        <FiChevronRight aria-label="next page" />
      </Button>
      <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FiChevronsRight aria-label="last page" />
      </Button>
    </HStack>
  );
};

export default Paginator;
