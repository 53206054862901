import ApiClient from '../api/ApiClient';
import { CountryDto } from './types';

export class CountriesService {
  private readonly apiBase: string;
  private readonly apiClient: ApiClient;

  constructor() {
    this.apiBase = 'countries';
    this.apiClient = new ApiClient();
  }

  async fetchCountries(): Promise<CountryDto[]> {
    // Remove memoization to ensure we always get fresh data
    return this.apiClient.get(`${this.apiBase}/role`);
  }

  async fetchRetailStockCountries(): Promise<CountryDto[]> {
    return this.apiClient.get(`${this.apiBase}/retail-stock`);
  }
}
