import { ProductsService } from './products/ProductsService';
import { ProductDto, ProductFilterDto } from './products/types';

const productsService = new ProductsService();

export const exportAll = async (
  filter: ProductFilterDto,
  total: number,
  onNewRequest: (start: number) => void,
): Promise<ProductDto[][]> => {
  const pageSize = 200;
  const allData: ProductDto[][] = [];
  const pages = Math.ceil(total / pageSize);

  for (let i = 0; i < pages; i++) {
    const start = i * pageSize;
    onNewRequest(start);
    try {
      const response = await productsService.searchProductsAssortment({
        ...filter,
        start: start,
        count: pageSize,
      });

      if (response.data.length > 0) {
        allData.push(response.data);
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }

  return allData;
};
