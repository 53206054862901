class BooleanValues {
  name: string = 'boolean';
  trueValue: string = 'true';
  falseValue: string = 'false';

  static readonly Boolean = {
    name: 'boolean',
    trueValue: 'true',
    falseValue: 'false',
  } as BooleanValues;

  static readonly Enable = {
    name: 'enable',
    trueValue: 'enabled',
    falseValue: 'disabled',
  } as BooleanValues;

  static readonly Active = {
    name: 'active',
    trueValue: 'activated',
    falseValue: 'deactivated',
  } as BooleanValues;

  static readonly Yes = {
    name: 'yes',
    trueValue: 'Yes',
    falseValue: 'No',
  } as BooleanValues;

  static readonly Binary = {
    name: 'binary',
    trueValue: 'Yes',
    falseValue: 'No',
  } as BooleanValues;

  static fromName(name: string | undefined): BooleanValues {
    switch (name) {
      case this.Enable.name:
        return BooleanValues.Enable;
      case this.Active.name:
        return BooleanValues.Active;
      case this.Yes.name:
        return BooleanValues.Yes;
      case this.Binary.name:
        return BooleanValues.Binary;
      default:
        return BooleanValues.Boolean;
    }
  }
}

export default BooleanValues;
