import { EditIcon } from '@chakra-ui/icons';
import { Flex, FlexProps, useDisclosure } from '@chakra-ui/react';
import { FC, useState } from 'react';
import BooleanValues from '../../common/BooleanValues';
import { useSearch } from '../../contexts/SearchContext';
import { ProductDto, ProductUpdateDto } from '../../core/products/types';
import { productsService } from '../../core/services';
import { Field, OptionalBooleanField } from '../misc/Fields';
import EditModal from '../UI/EditModal';

type ProductEcomInfoProps = Pick<
  ProductDto,
  | 'e_reservation'
  | 'is_available'
  | 'is_archived'
  | 'is_p40'
  | 'P40_asset'
  | 'OOOstatus'
  | 'CCExpressEnabled'
  | 'is_alert_stock'
  | 'is_perpetual'
  | 'is_searchable'
  | 'country'
  | 'sf_quantity'
  | 'product_type_purchase'
  | 'totalStock'
  | 'storeCount'
> &
  FlexProps & {
    onProductUpdate?: () => void;
  };

const ProductEcomInfo: FC<ProductEcomInfoProps> = ({
  e_reservation,
  is_available,
  is_archived,
  is_p40,
  P40_asset,
  OOOstatus,
  CCExpressEnabled,
  is_alert_stock,
  is_perpetual,
  is_searchable,
  country,
  sf_quantity,
  id,
  product_type_purchase,
  totalStock,
  storeCount,
  onProductUpdate,
  ...rest
}) => {
  const { role } = useSearch();

  const [selectedField, setSelectedField] = useState<{ label: string; value: string }>();
  const [options, setOptions] = useState<{ label: string; value: string | undefined }[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const update = (productId: string, body: ProductUpdateDto) => {
    productsService
      .updateProduct(productId, body)
      .then(data => {
        return data;
      })
      .catch(err => {
        throw err;
      })
      .finally(() => {
        onProductUpdate?.();
        onClose();
      });

    productsService
      .updateProductProd(productId, body)
      .then(data => {
        return data;
      })
      .catch(err => {
        throw err;
      })
      .finally(() => {
        onProductUpdate?.();
        onClose();
      });
  };

  const openModal = (
    fieldName: { label: string; value: string; locale?: string[] },
    options: { label: string; value: string | undefined }[],
  ) => {
    setSelectedField(fieldName);
    setOptions(options);
    onOpen();
  };

  return (
    <Flex direction={'column'} gap="2" align={'stretch'} {...rest}>
      <OptionalBooleanField
        name="Is searchable"
        value={is_searchable}
        type={BooleanValues.Yes.name}
        defaultText={BooleanValues.Yes.trueValue}
      />
      <OptionalBooleanField
        name="Is available"
        value={is_available}
        type={BooleanValues.Yes.name}
        defaultText={BooleanValues.Yes.falseValue}
      />
      <OptionalBooleanField
        name="Is archived"
        value={is_archived}
        type={BooleanValues.Yes.name}
        defaultText={BooleanValues.Yes.falseValue}
      />
      <OptionalBooleanField
        name="E-Reservation"
        value={e_reservation}
        type={BooleanValues.Yes.name}
        defaultText={BooleanValues.Yes.falseValue}
        icon={
          country && role.canEdit ? (
            <EditIcon
              onClick={() =>
                openModal(
                  {
                    label: 'E-Reservation',
                    value: 'c_ereservation',
                    locale: country.locales,
                  },
                  [
                    // { label: '- None -', value: 'null' },
                    { label: 'Available', value: 'available' },
                    { label: 'Not available', value: 'notavailable' },
                  ],
                )
              }
            />
          ) : null
        }
      />
      <OptionalBooleanField
        name="Is CC Express"
        value={CCExpressEnabled as boolean}
        type={BooleanValues.Yes.name}
        defaultText={BooleanValues.Yes.falseValue}
        icon={
          country && role.canEdit ? (
            <EditIcon
              onClick={() =>
                openModal({ label: 'Is CC Express', value: 'c_isCCExpressEnabled' }, [
                  { label: '- None -', value: 'null' },
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ])
              }
            />
          ) : null
        }
      />
      <OptionalBooleanField
        name="Is P40"
        value={is_p40}
        type={BooleanValues.Yes.name}
        defaultText={BooleanValues.Yes.falseValue}
      />
      <Field
        name="P40 Content"
        value={P40_asset}
        icon={
          country && role.canEdit ? (
            <EditIcon
              onClick={() =>
                openModal(
                  {
                    label: 'P40 Content',
                    value: 'c_p40ContentAsset',
                    locale: country.locales,
                  },
                  [
                    { label: '- None -', value: 'null' },
                    {
                      label: 'P40 Product Label',
                      value: 'p40-product-label',
                    },
                    {
                      label: 'P40 Product Label 1',
                      value: 'p40-product-label1',
                    },
                    {
                      label: 'P40 Product Label 2',
                      value: 'p40-product-label2',
                    },
                    {
                      label: 'P40 Product Label 3',
                      value: 'p40-product-label3',
                    },
                    {
                      label: 'P40 Product Label 4',
                      value: 'p40-product-label4',
                    },
                    {
                      label: 'P40 Product Label 5',
                      value: 'p40-product-label5',
                    },
                    {
                      label: 'P40 Product Label 6',
                      value: 'p40-product-label6',
                    },
                    {
                      label: 'P40 Product Label 7',
                      value: 'p40-product-label7',
                    },
                    {
                      label: 'P40 Product Label 8',
                      value: 'p40-product-label8',
                    },
                    {
                      label: 'P40 Product Label 9',
                      value: 'p40-product-label9',
                    },
                    {
                      label: 'P40 Product Label 10',
                      value: 'p40-product-label10',
                    },
                  ],
                )
              }
            />
          ) : null
        }
      />
      <Field
        name="Out of Stock Status"
        value={OOOstatus}
        icon={
          country && role.canEdit ? (
            <EditIcon
              onClick={() =>
                openModal(
                  {
                    label: 'Out of Stock Status',
                    value: 'c_OOOstatus',
                    locale: country.locales,
                  },
                  [
                    { label: '- None -', value: 'null' },
                    {
                      label: 'Temporary out of stock online',
                      value: 'temporary.outofstock.online',
                    },
                    {
                      label: 'Temporary out of stock everywhere',
                      value: 'temporary.outofstock.everywhere',
                    },
                    {
                      label: 'Permanently sold out everywhere',
                      value: 'permanently.soldout.everywhere',
                    },
                    { label: 'Permanently sold out online', value: 'permanently.soldout.online' },
                  ],
                )
              }
            />
          ) : null
        }
      />
      <OptionalBooleanField
        name="Is Alert Stock"
        value={is_alert_stock}
        type={BooleanValues.Yes.name}
        defaultText={BooleanValues.Yes.falseValue}
        icon={
          country && role.canEdit ? (
            <EditIcon
              onClick={() =>
                openModal(
                  { label: 'Is Alert Stock', value: 'c_isAlertStock', locale: country.locales },
                  [
                    { label: '- None -', value: 'null' },
                    { label: 'True', value: '1' },
                    { label: 'False', value: '0' },
                  ],
                )
              }
            />
          ) : null
        }
      />
      <Field
        name="Type Purchase"
        value={product_type_purchase}
        icon={
          country && role.canEdit ? (
            <EditIcon
              onClick={() =>
                openModal(
                  {
                    label: 'Type Purchase',
                    value: 'c_productTypePurchase',
                  },
                  [
                    { label: '- None -', value: '' },
                    {
                      label: 'Personalised',
                      value: 'personalised',
                    },
                    {
                      label: 'Stitching',
                      value: 'stitching',
                    },
                    {
                      label: 'Special',
                      value: 'special',
                    },
                    {
                      label: 'Standard',
                      value: 'standard',
                    },
                    {
                      label: 'Super special (CH - twint)',
                      value: 'superspecial',
                    },
                    {
                      label: 'Exclude type 1',
                      value: 'exclusionlist1',
                    },
                    {
                      label: 'Exclude type 2',
                      value: 'exclusionlist2',
                    },
                    {
                      label: 'Exclude type 3',
                      value: 'exclusionlist3',
                    },
                    {
                      label: 'Exclude type 4',
                      value: 'exclusionlist4',
                    },
                    {
                      label: 'Exclude type 5',
                      value: 'exclusionlist5',
                    },
                    {
                      label: 'Exclude type 6',
                      value: 'exclusionlist6',
                    },
                    {
                      label: 'Exclude type 7',
                      value: 'exclusionlist7',
                    },
                    {
                      label: 'Exclude type 8',
                      value: 'exclusionlist8',
                    },
                    {
                      label: 'Exclude type 9',
                      value: 'exclusionlist9',
                    },
                    {
                      label: 'Exclude type 10',
                      value: 'exclusionlist10',
                    },
                  ],
                )
              }
            />
          ) : null
        }
      />
      {id && (
        <EditModal
          isOpen={isOpen}
          onClose={onClose}
          header={`Change ${selectedField?.label} - ${id}`}
          subheader={`${country?.inventoryId}`}
          options={options}
          selectedField={selectedField!}
          productId={id}
          update={update}
        />
      )}
      <OptionalBooleanField
        name="Is perpetual"
        value={is_perpetual}
        type={BooleanValues.Yes.name}
        defaultText={BooleanValues.Yes.falseValue}
      />
      {country && (
        <>
          <Field
            name="Salesforce quantity"
            value={`${sf_quantity} unit(s)`}
            direction="row"
            gap={6}
          />
          <Field name="Retail Stock" value={`${totalStock} unit(s)`} direction="row" gap={6} />
          <Field
            name="Stores with Stock"
            value={`${storeCount} store(s)`}
            direction="row"
            gap={6}
          />
        </>
      )}
    </Flex>
  );
};

export default ProductEcomInfo;
