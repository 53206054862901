import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRoles = [] }) => {
  const { isAuthenticated, user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    // You could show a loading spinner here
    return <div>Loading authentication status...</div>;
  }

  if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If roles are specified, check if the user has the required roles
  if (requiredRoles.length > 0) {
    const userRoles = user?.roles || [];
    // Vérifie si l'utilisateur a un des rôles requis, en prenant en compte le format PAYS.ROLE
    const hasRequiredRole = requiredRoles.some(requiredRole =>
      userRoles.some((userRole: string) => {
        const [, role] = userRole.split('.');
        return role === requiredRole;
      }),
    );

    if (!hasRequiredRole) {
      // Redirect to unauthorized page
      return <Navigate to="/unauthorized" replace />;
    }
  }

  // User is authenticated and has the required roles
  return <>{children}</>;
};

export default ProtectedRoute;
