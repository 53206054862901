import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

export const DEFAULT_PAGE_SIZE = 15;

type PaginationContextType = {
  total: number;
  totalAssortment: number;
  pageIndex: number;
  setPageIndex: Dispatch<SetStateAction<number>>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  pageCount: number;
  setPageCount: Dispatch<SetStateAction<number>>;
  canNextPage: boolean;
  setCanNextPage: Dispatch<SetStateAction<boolean>>;
  canPreviousPage: boolean;
  setCanPreviousPage: Dispatch<SetStateAction<boolean>>;
};
const noop = () => {};

const defaultPaginationContext: PaginationContextType = {
  total: 0,
  totalAssortment: 0,
  pageIndex: 0,
  setPageIndex: noop,
  pageSize: DEFAULT_PAGE_SIZE,
  setPageSize: noop,
  pageCount: 1,
  setPageCount: noop,
  canNextPage: false,
  setCanNextPage: noop,
  canPreviousPage: false,
  setCanPreviousPage: noop,
};

const PaginationContext = createContext<typeof defaultPaginationContext>(defaultPaginationContext);

type PaginationProviderProps = {
  total: number;
  totalAssortment?: number;
  children?: React.ReactNode;
};
export const PaginationProvider: FC<PaginationProviderProps> = ({
  total,
  totalAssortment,
  children,
}) => {
  const [pageIndex, setPageIndex] = useState(defaultPaginationContext.pageIndex);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageCount, setPageCount] = useState(0);
  const [canNextPage, setCanNextPage] = useState(total > DEFAULT_PAGE_SIZE);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  return (
    <PaginationContext.Provider
      value={{
        total,
        totalAssortment: totalAssortment || 0,
        pageIndex,
        setPageIndex,
        pageSize,
        setPageSize,
        pageCount,
        setPageCount,
        canNextPage,
        setCanNextPage,
        canPreviousPage,
        setCanPreviousPage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => {
  const {
    total,
    totalAssortment,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    pageCount,
    setPageCount,
    canNextPage,
    setCanNextPage,
    canPreviousPage,
    setCanPreviousPage,
  } = useContext(PaginationContext);

  const reset = () => {
    setPageIndex(0);
    setPageSize(defaultPaginationContext.pageSize);
  };

  const gotoPage = (pageIndex: number) => setPageIndex(pageIndex);
  const nextPage = () => canNextPage && setPageIndex(prev => prev + 1);
  const previousPage = () => canPreviousPage && setPageIndex(prev => prev - 1);

  useEffect(() => {
    const newPageCount = Math.ceil(total / pageSize);
    setPageCount(newPageCount);
    setCanNextPage(newPageCount > pageIndex + 1);
    setCanPreviousPage(pageIndex - 1 >= 0);
  }, [
    total,
    totalAssortment,
    pageIndex,
    pageSize,
    //
    setPageCount,
    setCanNextPage,
    setCanPreviousPage,
  ]);

  const self = {
    total,
    totalAssortment,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    pageCount,
    setPageCount,
    canNextPage,
    canPreviousPage,
    reset,
    gotoPage,
    nextPage,
    previousPage,
  };

  return { ...self };
};
