import { Configuration, LogLevel } from '@azure/msal-browser';

// MSAL configuration
export const msalConfig: Configuration = {
  auth: {
    clientId: '0f8f4078-fc47-4fc2-83ab-c45b24d32a27',
    authority: 'https://login.microsoftonline.com/fa465c02-c505-4574-993d-a392c07f2a77',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

// Add scopes here for access token request
export const loginRequest = {
  scopes: ['openid', 'profile', 'email', 'User.Read'],
};

// Add your API's endpoint here
export const apiConfig = {
  baseUrl: process.env.REACT_APP_API_BASE_URL || '',
  loginEndpoint: '/auth/login',
  logoutEndpoint: '/auth/logout',
  userInfoEndpoint: '/auth/me',
};
