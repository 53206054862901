import { Box, Flex, HStack, Icon, Spacer, Tag, Text, useRadio, VStack } from '@chakra-ui/react';
import { FiCheckCircle } from 'react-icons/fi';
import RuleType from '../../common/RuleType';

interface RuleTypeOptionProps {
  type: RuleType;
  radioProps: any;
  currentValue?: number;
}

const RuleTypeOption = ({ type, radioProps, currentValue }: RuleTypeOptionProps) => {
  const radio = radioProps({ value: type.name });
  const { getInputProps, getRadioProps } = useRadio(radio);
  const input = getInputProps();
  const checkbox = getRadioProps();

  // On extrait isChecked de radio et on stocke les autres props dans radioWithoutIsChecked
  const { isChecked, ...radioWithoutIsChecked } = radio;

  return (
    <Box as="label" {...radioWithoutIsChecked}>
      <input {...input} />
      <Flex
        {...checkbox}
        key={type.name}
        cursor="pointer"
        rounded="md"
        border="1px"
        borderColor="gray.200"
        _checked={{
          bold: 'true',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        _hover={{
          boxShadow: 'md',
        }}
        px={5}
        py={3}
      >
        <VStack alignItems="start">
          <HStack spacing="4">
            <Text>{type.display}</Text>
            {type.icon && <Icon fontSize="16" as={type.icon} />}
            {currentValue && <Tag colorScheme="yellow">{`currently ${currentValue} unit(s)`}</Tag>}
          </HStack>
        </VStack>
        <Spacer />
        {isChecked && <FiCheckCircle color="green" />}
      </Flex>
    </Box>
  );
};

export default RuleTypeOption;
