import colors from './colors';
import fonts from './fonts';
import shadows from './shadows';

const foundations = {
  fonts,
  shadows,
  colors,
};

export default foundations;
