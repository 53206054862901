export const styles = {
  global: {
    html: {
      position: 'relative',
      minHeight: '100%',
    },
    body: {
      minHeight: '100%',
    },
  },
};

export default styles;
