import { Button, Flex, FormControl, Input, Select, Stack, Tooltip, VStack } from '@chakra-ui/react';
import {
  ActionMeta,
  AsyncCreatableSelect,
  GroupBase,
  MultiValue,
  OptionBase,
  Options,
  OptionsOrGroups,
  SelectInstance,
} from 'chakra-react-select';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useSearch } from '../../contexts/SearchContext';
import { CountryDto } from '../../core/countries/types';
import { ProductFilterDto } from '../../core/products/types';
import { countriesService, productsService } from '../../core/services';

interface SkuOption extends OptionBase {
  label: string;
  value: string;
}

/**
 * StockFilter component for filtering stock items.
 *
 * @component
 * @param {object} rest - The rest of the props passed to the component.
 * @returns {JSX.Element} The StockFilter component.
 */
const StockFilter = ({ ...rest }) => {
  const { productFilter, setFilters, isLoading } = useSearch();
  const [filter, setFilter] = useState(productFilter);
  const [countries, setCountries] = useState<CountryDto[]>([]);
  const skuSelectRef = useRef<SelectInstance<SkuOption, true, GroupBase<SkuOption>>>(null);

  useEffect(() => {
    setFilter({
      ...productFilter,
    });
    if (countries.length === 1) {
      setFilter(prev => ({ ...prev, inventoryId: countries[0].inventoryId }));
    }
  }, [productFilter, countries]);

  useEffect(() => {
    if (countries.length === 1 && !productFilter.inventoryId) {
      setFilters(filter);
    }
  }, [countries, setFilters, filter, productFilter]);

  useEffect(() => {
    countriesService.fetchCountries().then(setCountries).catch(console.log);
  }, [setCountries]);

  // handlers
  const handleUserInput = (event: any) => {
    event.preventDefault();
    setFilter((prev: ProductFilterDto) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const handleCountryChange = (event: any) => {
    event.preventDefault();
    const value = event.target.value;
    setFilter((prev: ProductFilterDto) => {
      return {
        ...prev,
        [event.target.name]: value,
        name: !!value ? '' : filter.name,
      };
    });
  };

  const loadSkuOptions = (
    partialSku: string,
    callback: (options: OptionsOrGroups<SkuOption, GroupBase<SkuOption>>) => void,
  ) => {
    setTimeout(async () => {
      const skus = await productsService.getProductsSuggestions(partialSku);
      callback(
        skus.map(s => {
          return {
            value: s,
            label: s,
          } as SkuOption;
        }),
      );
    }, 400);
  };

  const handleSkusChange = (newValue: MultiValue<SkuOption>) => {
    setFilter((prev: ProductFilterDto) => {
      return {
        ...prev,
        skus: newValue.map(o => o.value),
      };
    });
  };

  const handleSkusCreate = (inputValue: string) => {
    const currentOptions = skuSelectRef.current?.getValue() as Options<SkuOption>;
    const newOptions = inputValue.split(' ').map(v => {
      return {
        value: v,
        label: v,
      } as SkuOption;
    });
    skuSelectRef?.current?.onChange(
      [...currentOptions, ...newOptions] as MultiValue<SkuOption>,
      {} as ActionMeta<SkuOption>,
    );
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFilters(filter);
  };

  return (
    <Stack {...rest}>
      <form onSubmit={handleOnSubmit}>
        <VStack>
          <FormControl id="country">
            {countries.length > 1 && (
              <Select
                name="inventoryId"
                placeholder="All countries..."
                onChange={handleCountryChange}
                isDisabled={!countries}
              >
                {countries?.map((e, i) => (
                  <option key={i} value={e.inventoryId}>
                    {`${e.code} - ${e.name}`}
                  </option>
                ))}
              </Select>
            )}
            {countries.length === 1 && (
              <Select
                name="inventoryId"
                isDisabled
                opacity="1 !important"
                overflowY={'auto'}
                value={countries[0].inventoryId}
              >
                <option value={countries[0].inventoryId}>
                  {`${countries[0].code} - ${countries[0].name}`}
                </option>
              </Select>
            )}
          </FormControl>
          <FormControl id="sku">
            <AsyncCreatableSelect<SkuOption, true, GroupBase<SkuOption>>
              ref={skuSelectRef}
              isMulti
              placeholder="SKU"
              colorScheme="yellow"
              cacheOptions={true}
              allowCreateWhileLoading={true}
              onCreateOption={handleSkusCreate}
              onChange={handleSkusChange}
              loadOptions={loadSkuOptions}
              chakraStyles={{
                dropdownIndicator: provided => ({
                  ...provided,
                  bg: 'transparent',
                  px: 2,
                  cursor: 'inherit',
                }),
                valueContainer: provided => ({
                  ...provided,
                  maxH: '200px',
                  overflowY: 'auto',
                }),
                indicatorSeparator: provided => ({
                  ...provided,
                  display: 'none',
                }),
              }}
            />
          </FormControl>
          {countries.length > 1 && (
            <FormControl id="name">
              <Tooltip label="Name is available when no country has been selected">
                <span>
                  <Input
                    name="name"
                    placeholder="Name"
                    value={filter.name}
                    onChange={handleUserInput}
                    isDisabled={!!filter.inventoryId}
                  />
                </span>
              </Tooltip>
            </FormControl>
          )}
          <Flex w="100%">
            <Button type="submit" loadingText="Loading" isLoading={isLoading} flexGrow={1}>
              Search
            </Button>
          </Flex>
        </VStack>
      </form>
    </Stack>
  );
};

export default StockFilter;
