import { FC, useEffect } from 'react';
import Navbar from './Navbar';

type LayoutProps = {
  title: string;
  children?: React.ReactNode
};
const Layout: FC<LayoutProps> = ({ title, children }) => {
  useEffect(() => {
    document.title = title;
  });

  return <Navbar title={title}>{children}</Navbar>;
};

export default Layout;
