import { Center, Checkbox, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import {
  sortByAutomatic,
  sortById,
  sortByIsPurchasable,
  sortByManual,
  sortByRetailStock,
  sortByRuleType,
  sortBySalesforce,
  sortByTotalStore,
} from '../../common/product/utils';
import SortableColumnHeader from '../Table/SortableColumn';
import { CenterFlex, HeaderText } from '../Table/utils';

interface StockResultsHeaderProps {
  hasCountry: boolean;
  selectionStatus: 'all' | 'some' | 'none';
  selectAll: (selected: boolean) => void;
}
const StockResultsHeader: FC<StockResultsHeaderProps> = ({
  hasCountry,
  selectionStatus,
  selectAll,
}) => {
  const additionalColumns = useMemo(() => {
    return hasCountry ? (
      <>
        <CenterFlex>
          <HeaderText>Retail Stock</HeaderText>
          <SortableColumnHeader
            sortAscending={{
              name: sortByRetailStock.name,
              sortAscendingFn: sortByRetailStock,
            }}
          />
        </CenterFlex>
        <CenterFlex>
          <HeaderText>Stores with Stock</HeaderText>
          <SortableColumnHeader
            sortAscending={{
              name: sortByTotalStore.name,
              sortAscendingFn: sortByTotalStore,
            }}
          />
        </CenterFlex>
        <CenterFlex>
          <HeaderText>Rule</HeaderText>
          <SortableColumnHeader
            sortAscending={{
              name: sortByRuleType.name,
              sortAscendingFn: sortByRuleType,
            }}
          />
        </CenterFlex>
        <CenterFlex>
          <HeaderText>Purchasable</HeaderText>
          <SortableColumnHeader
            sortAscending={{
              name: sortByIsPurchasable.name,
              sortAscendingFn: sortByIsPurchasable,
            }}
          />
        </CenterFlex>
        <CenterFlex>
          <HeaderText>Salesforce</HeaderText>
          <SortableColumnHeader
            sortAscending={{
              name: sortBySalesforce.name,
              sortAscendingFn: sortBySalesforce,
            }}
          />
        </CenterFlex>
        <CenterFlex>
          <HeaderText>Manual</HeaderText>
          <SortableColumnHeader
            sortAscending={{
              name: sortByManual.name,
              sortAscendingFn: sortByManual,
            }}
          />
        </CenterFlex>
        <CenterFlex>
          <HeaderText>Automatic</HeaderText>
          <SortableColumnHeader
            sortAscending={{
              name: sortByAutomatic.name,
              sortAscendingFn: sortByAutomatic,
            }}
          />
        </CenterFlex>
      </>
    ) : (
      <CenterFlex>
        <HeaderText>Collection</HeaderText>
      </CenterFlex>
    );
  }, [hasCountry]);

  return (
    <Flex
      w={'100%'}
      alignItems="center"
      pb={1}
      borderBottom={'solid 1px'}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
    >
      <Center px={3}>
        <Checkbox
          isIndeterminate={selectionStatus === 'some'}
          isChecked={selectionStatus === 'all'}
          onChange={element => {
            selectAll(element.currentTarget.checked);
          }}
        />
      </Center>
      <Center w={'99%'}>
        <Flex
          w={'100%'}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Flex alignItems={'center'} gap={{ base: 0, md: 2 }} w="35%">
            <Flex
              direction={{
                base: 'column',
                md: 'row',
              }}
              gap={{ base: 0, md: 1 }}
            >
              <Text fontWeight={'semibold'}>SKU</Text>
              <Text
                textColor={useColorModeValue('blackAlpha.500', 'whiteAlpha.500')}
                fontWeight={'semibold'}
                noOfLines={1}
              >
                Name
              </Text>
            </Flex>
            <SortableColumnHeader
              sortAscending={{
                name: sortById.name,
                sortAscendingFn: sortById,
              }}
            />
          </Flex>
          <Flex w="65%">{additionalColumns}</Flex>
        </Flex>
      </Center>
    </Flex>
  );
};

export default StockResultsHeader;
