import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  ColorModeProvider,
  Container,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BsShieldShaded } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';

const Login: React.FC = () => {
  const { isAuthenticated, loading, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { onClose } = useDisclosure();

  // Get the page to redirect to after login
  const from = (location.state as any)?.from?.pathname || '/';

  // If already authenticated, redirect to the previous page
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, from]);

  const handleLogin = () => {
    login();
  };

  if (loading) {
    return (
      <Container maxW="container.sm">
        <Center h="80vh">
          <Spinner size="xl" />
        </Center>
      </Container>
    );
  }

  return (
    <ColorModeProvider value="light">
      <Modal
        isOpen={true}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        motionPreset="slideInBottom"
        size="xl"
      >
        <ModalOverlay bg="transparent" />
        <ModalContent boxShadow={{ base: 'none', md: 'dark-lg' }} borderRadius={'xl'}>
          <ModalBody>
            <Card align="center" boxShadow={'none'}>
              <Flex w="100%" justifyContent={'center'} p="10">
                <Image src="/swatch_logo_black.png" alt="Swatch Logo" width="175px" />
              </Flex>
              <CardHeader>
                <Box layerStyle="pulse">
                  <Icon as={BsShieldShaded} boxSize={85} color="gray.900" className="shield-icon" />
                </Box>
              </CardHeader>
              <CardBody>
                <VStack spacing={5}>
                  <Text textAlign={'center'} color="GrayText">
                    Click the button below to sign in with your organization credentials.
                  </Text>
                </VStack>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter w="100%" marginBottom={5}>
            <Box w="100%" textAlign={'center'}>
              <VStack spacing={5} w={'100%'}>
                <Button
                  colorScheme="blackAlpha"
                  bg="#000"
                  _hover={{ bg: 'gray.800' }}
                  size="lg"
                  w={'55%'}
                  margin="auto"
                  onClick={handleLogin}
                  fontSize={'lg'}
                >
                  <Icon as={FaUserCircle} mr={5} /> Sign in
                </Button>
              </VStack>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ColorModeProvider>
  );
};

export default Login;
