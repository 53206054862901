import { Box, BoxProps, Flex, FlexProps, Grid, Text, Wrap } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import BooleanValues from '../../common/BooleanValues';

type FieldProps = {
  name: string | string[];
  value?: string | number | string[];
  light?: boolean;
  icon?: ReactNode;
} & FlexProps;

export const Field: FC<FieldProps> = ({ name, value, light = false, icon, ...rest }) => (
  <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 0, md: 6 }} {...rest}>
    <Flex flex={2} alignItems="center">
      <Text color="gray.500">{name}</Text>
      {icon && (
        <Box as="span" display="inline-block" color="white" ml={4} style={{ cursor: 'pointer' }}>
          {icon}
        </Box>
      )}
    </Flex>
    <Text flex={1} color={light ? 'gray.500' : 'inherit'} fontStyle={light ? 'oblique' : 'initial'}>
      {Array.isArray(value) && value.every(item => typeof item === 'string')
        ? value.join(', ')
        : value ?? '-'}
    </Text>
  </Flex>
);

interface BoxFieldProps extends BoxProps {
  name: string;
  children?: React.ReactNode | React.ReactNode[];
}
export const BoxField = ({ name, children, ...rest }: BoxFieldProps) => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} {...rest}>
      <Text color="gray.500">{name}</Text>
      <Box>{children}</Box>
    </Grid>
  );
};

interface WrapFieldProps extends BoxProps {
  name: string;
  children?: React.ReactNode | React.ReactNode[];
}
export const WrapField = ({ name, children, ...rest }: WrapFieldProps) => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} {...rest}>
      <Text color="gray.500">{name}</Text>
      <Wrap justify="stretch" maxH="33vh" overflowY="auto" overflowX="hidden">
        {children}
      </Wrap>
    </Grid>
  );
};

type OptionalFieldProps = {
  name: string;
  value?: boolean;
  type?: string;
  defaultText?: string;
  icon?: ReactNode;
} & FlexProps;
export const OptionalBooleanField: FC<OptionalFieldProps> = ({
  name,
  value,
  type,
  defaultText = 'N/A',
  icon,
  ...rest
}) => {
  const booleanType = BooleanValues.fromName(type);
  let displayedValue = defaultText;

  if (value !== undefined) {
    displayedValue = value ? booleanType.trueValue : booleanType.falseValue;
  }

  return (
    <Field
      name={name}
      value={displayedValue}
      light={value === undefined}
      w="full"
      direction="row"
      gap={6}
      icon={icon}
      {...rest}
    />
  );
};
