import { ProductDto } from '../../core/products/types';
import { isPurchasable } from './utils';

type ExportProductDefault = {
  SKU: string;
  NAME: string;
  COLLECTION: string;
};

type ExportProductCountry = {
  SKU: string;
  NAME: string;
  PRICE: string;
  COUNTRY: string;
  'RULE TYPE': string;
  PURCHASABLE: string;
  SALESFORCE: string;
  MANUAL: string;
  AUTOMATIC: string;
  SEARCHABLE: string;
  AVAILABLE: string;
  ARCHIVED: string;
  'E-RESERVATION': string;
  P40: string;
  'P40 CONTENT': string;
  'OUT OF STOCK STATUS': string;
  'IS CC EXPRESS': string;
  'IS ALERT STOCK': string;
  PERPETUAL: string;
  'SALES STATUS': string;
  'ORDER STATUS': string;
  'RETAIL STOCK': string;
  'STORES WITH STOCK': string;
};
type ExportProduct = ExportProductDefault | ExportProductCountry;

const printBoolean = (val: boolean | undefined) => (val === undefined ? '-' : val ? 'yes' : 'no');
const printNumber = (val: number | undefined) => (val === undefined ? '-' : `${val}`);

export const ConvertToExport = (p: ProductDto): ExportProduct => {
  const hasCountry = !!p.country?.code;
  const purchasable = isPurchasable(p);

  return hasCountry
    ? {
        SKU: p.id,
        NAME: p.name,
        PRICE: p.price ?? '-',
        COUNTRY: p.country?.code ?? '',
        'RULE TYPE': p.rule?.type ?? 'automatic',
        PURCHASABLE: printBoolean(purchasable),
        SALESFORCE: printNumber(p.sf_quantity),
        MANUAL: printNumber(p.rule?.quantity),
        AUTOMATIC: printNumber(p.automatic_quantity),
        SEARCHABLE: printBoolean(p.is_searchable),
        AVAILABLE: printBoolean(p.is_available),
        ARCHIVED: printBoolean(p.is_archived),
        'E-RESERVATION': printBoolean(p.e_reservation),
        P40: printBoolean(p.is_p40),
        'P40 CONTENT': p.P40_asset ?? '-',
        'OUT OF STOCK STATUS': p.OOOstatus ?? '-',
        'IS CC EXPRESS': printBoolean(p.CCExpressEnabled),
        'IS ALERT STOCK': printBoolean(p.is_alert_stock),
        PERPETUAL: printBoolean(p.is_perpetual),
        'SALES STATUS': printNumber(p.sales_status),
        'ORDER STATUS': printNumber(p.order_status),
        'RETAIL STOCK': printNumber(p.totalStock),
        'STORES WITH STOCK': printNumber(p.storeCount),
      }
    : {
        SKU: p.id,
        NAME: p.name,
        COLLECTION: p.collection_code,
      };
};
