import { createContext, FC, useContext, useState } from 'react';
import { defaultSort, SortType } from '../common/product/utils';

type SortingContextType = {
  sort: SortType;
  setSort: React.Dispatch<React.SetStateAction<SortType>>;
};
const noop = () => {};
const defaultSortingContext: SortingContextType = {
  sort: {
    name: defaultSort.name,
    sortFn: () => defaultSort,
  },
  setSort: noop,
};

const SortingContext = createContext<typeof defaultSortingContext>(defaultSortingContext);

type SortingProviderProps = {
  children?: React.ReactNode;
};
export const SortingProvider: FC<SortingProviderProps> = ({ children }) => {
  const [sort, setSort] = useState(defaultSortingContext.sort);

  return (
    <SortingContext.Provider
      value={{
        sort,
        setSort,
      }}
    >
      {children}
    </SortingContext.Provider>
  );
};

export function useSorting() {
  return useContext(SortingContext);
}
