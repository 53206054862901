import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
} from '@chakra-ui/react';
import { ApiError } from '../core/api/types';

interface InfoAlertProps extends AlertProps {
  title: string;
  description: string;
}
export const InfoAlert = ({ title, description, ...rest }: InfoAlertProps) => {
  return (
    <Alert {...rest}>
      <AlertIcon />
      <Box flex="1">
        <AlertTitle mr={2}>{title}</AlertTitle>
        <AlertDescription display="block">{description}</AlertDescription>
      </Box>
    </Alert>
  );
};

interface SuccessAlertProps extends AlertProps {
  title: string;
  description: string;
}
export const SuccessAlert = ({
  title,
  description,
  ...rest
}: SuccessAlertProps) => {
  return (
    <InfoAlert
      status="success"
      title={title}
      description={description}
      {...rest}
    />
  );
};

interface WarningAlertProps extends AlertProps {
  title: string;
  description: string;
}
export const WarningAlert = ({
  title,
  description,
  ...rest
}: WarningAlertProps) => {
  return (
    <InfoAlert
      status="warning"
      title={title}
      description={description}
      {...rest}
    />
  );
};

interface ErrorAlertProps extends AlertProps {
  error: ApiError;
}
export const ErrorAlert = ({ error, ...rest }: ErrorAlertProps) => {
  return (
    <InfoAlert
      status="error"
      title={`Error ${error.statusCode}`}
      description={error.status}
      {...rest}
    />
  );
};
