import {
  Box,
  Center,
  Image,
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
  Text,
} from '@chakra-ui/react';
import { ImageDto } from '../../core/products/types';

interface ProductImagesCarouselProps {
  sku: string;
  pictures: ImageDto[] | undefined;
}
const ProductImagesCarousel = ({
  sku,
  pictures,
}: ProductImagesCarouselProps) => {
  return !pictures ? (
    <Center>
      <Text>{`No pictures for ${sku}`}</Text>
    </Center>
  ) : (
    <Box bg={'white'} boxShadow="xs" rounded="md" p="1">
      <Tabs
        defaultIndex={0}
        variant="soft-rounded"
        align="end"
        colorScheme="yellow"
      >
        <TabPanels>
          {pictures.map((p, i) => (
            <TabPanel key={i}>
              <Image key={i}
                maxHeight={'40vh'}
                fit="cover"
                src={p.item?.mobileItem ?? p.item?.desktopItem}
                alt={`picture of ${sku}`}
              />
            </TabPanel>
          ))}
        </TabPanels>
        <TabList>
          {pictures.map((p, i) => (
            <Tab key={i}>{i + 1}</Tab>
          ))}
        </TabList>
      </Tabs>
    </Box>
  );
};

export default ProductImagesCarousel;
