import { IconType } from 'react-icons';
import { FiArrowRight, FiRefreshCcw, FiTool } from 'react-icons/fi';

class RuleType {
  name: string = 'automatic';
  display: string = 'Automatic';
  icon: IconType = FiArrowRight;

  static readonly Perpetual = {
    name: 'perpetual',
    display: 'Perpetual',
    icon: FiRefreshCcw,
  } as RuleType;
  static readonly Manual = {
    name: 'manual',
    display: 'Manual',
    icon: FiTool,
  } as RuleType;
  static readonly Automatic = {
    name: 'automatic',
    display: 'Automatic',
    icon: FiArrowRight,
  } as RuleType;

  static fromName(name: string | undefined): RuleType {
    switch (name) {
      case RuleType.Perpetual.name:
        return RuleType.Perpetual;
      case RuleType.Manual.name:
        return RuleType.Manual;
      case RuleType.Automatic.name:
      default:
        return RuleType.Automatic;
    }
  }
}

export default RuleType;
