import XLSX from 'sheetjs-style'
import FileSaver from 'file-saver'
import moment from 'moment'

export const getFileName = (indication: string | undefined) => {
  return `JOHNY_${indication !== undefined ? `${indication}_` : ''}${moment().format('YYYY-MM-DD_hhmm')}.xlsx`
}

export const exportToExcel = (data: object[], filename: string | undefined = undefined) => {
  const ws = XLSX.utils.json_to_sheet(data)
  const wb: XLSX.WorkBook = {
    Sheets: { data: ws },
    SheetNames: ['data']
  }
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array'
  });
  const excelBlob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  FileSaver.saveAs(excelBlob, getFileName(filename))
}

export const exportToClipboard = (data: object[]) => {
  const ws = XLSX.utils.json_to_sheet(data)
  return XLSX.utils.sheet_to_csv(ws, {
    FS: '\t'
  })
}
