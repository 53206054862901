import axios, { AxiosRequestConfig } from 'axios';
import { ApiError } from './types';

class ApiClient {
  private _axiosClient;

  constructor() {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';
    
    if (!apiBaseUrl) {
      console.warn('REACT_APP_API_BASE_URL environment variable is not set or empty');
    }

    this._axiosClient = axios.create({
      baseURL: apiBaseUrl,
      withCredentials: true, // Enable sending cookies with requests
    });

    this._axiosClient.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        // If we get a 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          // Redirect to login page if not already there
          if (window.location.pathname !== '/login') {
            window.location.href = `/login?redirect=${encodeURIComponent(window.location.pathname)}`;
          }
        }

        return Promise.reject({
          ...error.response?.data,
          status: error.response?.status,
        } as ApiError);
      },
    );
  }

  async post<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: AxiosRequestConfig,
  ): Promise<TResponse> {
    return this._axiosClient.post(path, payload, config).then(res => res.data as TResponse);
  }

  async patch<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: AxiosRequestConfig,
  ): Promise<TResponse> {
    return this._axiosClient.patch(path, payload, config).then(res => res.data as TResponse);
  }

  async get<TResponse>(path: string, config?: AxiosRequestConfig): Promise<TResponse> {
    return this._axiosClient.get(path, config).then(res => res.data as TResponse);
  }
}

export default ApiClient;
