import { Button, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

type AddRemoveButtonProps = {
  isAdd: boolean;
  toggle?: (add: boolean) => void;
} & ButtonProps;
const AddRemoveButton: FC<AddRemoveButtonProps> = ({
  isAdd,
  toggle,
  children,
  ...rest
}) => {
  return (
    <Button
      size={'xs'}
      colorScheme={isAdd ? 'green' : 'red'}
      alignItems="center"
      gap={1}
      onClick={() => toggle && toggle(isAdd)}
      {...rest}
    >
      {isAdd ? <FiPlus /> : <FiMinus />}
      {children}
    </Button>
  );
};

export default AddRemoveButton;
