import { Center, Heading, Stack, VStack } from '@chakra-ui/react';
import ProductDetail from '../components/Product/ProductDetail';
import StockFilter from '../components/Stock/StockFilter';
import StockResults from '../components/Stock/StockResults';
import { ProductProvider } from '../contexts/ProductContext';
import { SearchProvider } from '../contexts/SearchContext';
import { SortingProvider } from '../contexts/SortingContext';

const Stock = () => {
  return (
    <SearchProvider>
      <ProductProvider>
        <Stack
          direction={{
            base: 'column',
            md: 'row',
          }}
          w="full"
          align="stretch"
          spacing={4}
        >
          <VStack alignItems={'start'} w={{ base: 'full', md: '33%', lg: '25%' }}>
            <Center w={'full'}>
              <Heading size="md">Filters</Heading>
            </Center>
            <StockFilter w={'full'} />
          </VStack>
          <SortingProvider>
            <StockResults w={'full'}>
              <SortingProvider>
                <ProductDetail />
              </SortingProvider>
            </StockResults>
          </SortingProvider>
        </Stack>
      </ProductProvider>
    </SearchProvider>
  );
};

export default Stock;
