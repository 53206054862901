import { Flex, FlexProps, Text, TextProps, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';

export const HeaderText: FC<TextProps> = ({ ...rest }) => {
  return <Text fontWeight={'semibold'} noOfLines={2} textAlign={'center'} {...rest} />;
};

type ProductIdCellProps = {
  id: string;
  name: string;
} & FlexProps;
export const ProductIdCell: FC<ProductIdCellProps> = ({ id, name, ...rest }) => {
  return (
    <Flex
      direction={{
        base: 'column',
        md: 'row',
      }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      gap={{ base: 0, md: 2 }}
      w="100%"
      {...rest}
    >
      <Text flex={0}>{id}</Text>
      <Text
        flex={1}
        textColor={useColorModeValue('blackAlpha.500', 'whiteAlpha.500')}
        _hover={{
          textColor: useColorModeValue('blackAlpha.500', 'whiteAlpha.800'),
        }}
      >
        {name || '-'}
      </Text>
    </Flex>
  );
};

export const CenterFlex: FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex justifyContent={'center'} alignItems="center" overflowX={'hidden'} flex={1} {...rest}>
      {children}
    </Flex>
  );
};
