import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FC } from 'react';
import { LogsDto } from '../../core/logs-rule/types';
import { RuleDto } from '../../core/rules/types';

type LogsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  subheader?: string;
  ruleHistory?: LogsDto | LogsDto[];
  currentRule?: RuleDto;
};

const formatDate = (date: Date | string) => {
  if (!date) return '-';
  return new Intl.DateTimeFormat('fr', {
    timeStyle: 'short',
    dateStyle: 'short',
  }).format(new Date(date));
};

const LogsModal: FC<LogsModalProps> = ({
  isOpen,
  onClose,
  header,
  subheader,
  ruleHistory,
  currentRule,
}) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered size={'6xl'}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
      <ModalContent>
        <ModalHeader>
          {header}
          <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{subheader}</p>
        </ModalHeader>
        <ModalCloseButton />
        <FormControl>
          <ModalBody p={8}>
            <TableContainer mb={12}>
              <Table variant="striped" colorScheme={'gray.300'} fontSize={'smaller'}>
                <TableCaption textTransform={'uppercase'} fontWeight={'bold'} color={'green.300'}>
                  Current rule
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th textAlign={'center'}>Created at</Th>
                    <Th textAlign={'center'}>Updated at</Th>
                    <Th textAlign={'center'}>Rule type</Th>
                    <Th textAlign={'center'}>Author</Th>
                    <Th textAlign={'center'}>Lifetime</Th>
                    <Th textAlign={'center'}>Quantity</Th>
                    <Th textAlign={'center'}>Country</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentRule && (
                    <Tr
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      <Td textAlign={'center'}>{formatDate(currentRule.createdAt)}</Td>
                      <Td textAlign={'center'}>{formatDate(currentRule.updatedAt)}</Td>
                      <Td textAlign={'center'} fontWeight={'bold'}>
                        {currentRule.type}
                      </Td>
                      <Td textAlign={'center'}>
                        {currentRule.author.includes(':')
                          ? currentRule.author.split(':')[1]
                          : currentRule.author}
                      </Td>
                      <Td textAlign={'center'}>{formatDate(currentRule.lifetime)}</Td>
                      <Td textAlign={'center'}>{currentRule.quantity}</Td>
                      <Td textAlign={'center'}>{currentRule.countryCode}</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table variant="striped" colorScheme={'gray.300'}>
                <TableCaption textTransform={'uppercase'} fontWeight={'bold'} color={'blue.300'}>
                  Last 5 updates
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th textAlign={'center'}>Created at</Th>
                    <Th textAlign={'center'}>Rule type</Th>
                    <Th textAlign={'center'}>Author</Th>
                    <Th textAlign={'center'}>Lifetime</Th>
                    <Th textAlign={'center'}>Quantity</Th>
                    <Th textAlign={'center'}>Country</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {ruleHistory &&
                    Array.isArray(ruleHistory) &&
                    ruleHistory.map((log, index: number) => {
                      const isLastRow = index === ruleHistory.length - 1;
                      return (
                        <Tr
                          key={index}
                          style={{
                            textTransform: 'capitalize',
                            color: isLastRow ? 'darkseagreen' : 'inherit',
                            fontWeight: isLastRow ? 'bold' : 'inherit',
                          }}
                        >
                          <Td textAlign={'center'}>{formatDate(log.createdAt)}</Td>
                          <Td textAlign={'center'} fontWeight={'bold'}>
                            {log.ruletype}
                          </Td>
                          <Td textAlign={'center'}>
                            {log.author.includes(':') ? log.author.split(':')[1] : log.author}
                          </Td>
                          <Td textAlign={'center'}>{formatDate(log.lifetime)}</Td>
                          <Td textAlign={'center'}>{log.quantity}</Td>
                          <Td textAlign={'center'}>{log.countryCode}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </FormControl>
      </ModalContent>
    </Modal>
  );
};

export default LogsModal;
