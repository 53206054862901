import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { ApiError, DataList } from '../core/api/types';
import { Role } from '../core/auth/AuthService';
import { ProductDto, ProductFilterDto } from '../core/products/types';
import { authService, productsService } from '../core/services';
import { PaginationProvider } from './PaginationContext';

type SearchContextType = {
  productFilter: ProductFilterDto;
  isLoading: boolean;
  dataList: DataList<ProductDto>;
  role: Role;
  error: ApiError | undefined;
  setProductFilter: (filters: ProductFilterDto) => void;
  fetch: (pageIndex: number, pageSize: number) => void;
};

const defaultSearchContext: SearchContextType = {
  productFilter: {
    inventoryId: '',
    skus: [],
    name: '',
  },
  isLoading: false,
  dataList: { count: 0, total: 0, start: 0, data: [] },
  role: {} as Role,
  error: undefined,
  setProductFilter: function () {} as (filters: ProductFilterDto) => void,
  fetch: function () {} as (pageIndex: number, pageSize: number) => void,
};

const SearchContext = createContext<typeof defaultSearchContext>(defaultSearchContext);

type SearchProviderProps = {
  children?: React.ReactNode;
};
export const SearchProvider: FC<SearchProviderProps> = ({ children }) => {
  const [productFilter, setProductFilter] = useState(defaultSearchContext.productFilter);
  const [isLoading, setIsLoading] = useState(defaultSearchContext.isLoading);
  const [products, setProducts] = useState(defaultSearchContext.dataList);
  const [productsAssortment, setProductsAssortment] = useState(defaultSearchContext.dataList);
  const [error, setError] = useState(defaultSearchContext.error);
  const [role, setRole] = useState<Role>({} as Role);

  const fetch = useCallback(
    (pageIndex: number, pageSize: number) => {
      setIsLoading(true);
      setError(undefined);
      authService
        .fetchRoles()
        .then(data => setRole(data))
        .catch((err: ApiError) => {
          setError(err);
          throw err;
        });

      productsService
        .searchProducts({
          ...productFilter,
          start: pageIndex * pageSize,
          count: pageSize,
        })
        .then(data => {
          setProducts(data);
        })
        .catch((err: ApiError) => {
          setError(err);
          throw err;
        })
        .finally(() => setIsLoading(false));

      productsService
        .searchProductsAssortment({
          ...productFilter,
          start: pageIndex * pageSize,
          count: pageSize,
        })
        .then(data => {
          setProductsAssortment(data);
        })
        .catch((err: ApiError) => {
          setError(err);
          throw err;
        })
        .finally(() => setIsLoading(false));
    },
    [productFilter],
  );

  const providerValue = useMemo(
    () => ({
      productFilter: productFilter,
      setProductFilter: setProductFilter,
      isLoading: isLoading,
      dataList: products,
      role,
      error: error,
      fetch,
    }),
    [productFilter, setProductFilter, isLoading, products, role, error, fetch],
  );

  return (
    <SearchContext.Provider value={providerValue}>
      <PaginationProvider total={products.total} totalAssortment={productsAssortment.total}>
        {children}
      </PaginationProvider>
    </SearchContext.Provider>
  );
};

export function useSearch() {
  const { productFilter, setProductFilter, isLoading, dataList, error, role, fetch } =
    useContext(SearchContext);

  const setFilters = (filter: ProductFilterDto) => {
    setProductFilter(filter);
  };

  const self = {
    productFilter,
    isLoading,
    dataList,
    role,
    error,
    fetch,
    setFilters,
  };

  return { ...self };
}
