import { Icon, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import RuleType from '../../common/RuleType';
import { RuleDto } from '../../core/rules/types';

interface RuleTypeElementProps {
  rule?: RuleDto;
  perpetual?: boolean;
}
const RuleTypeElement = ({ rule, perpetual }: RuleTypeElementProps) => {
  let type;
  if (rule) {
    type = RuleType.fromName(rule?.type);
  } else if (perpetual === true) {
    type = RuleType.Perpetual;
  } else {
    type = RuleType.Automatic;
  }

  const tooltipLabel =
    type !== RuleType.Automatic
      ? `${type.display} until ${moment(rule?.lifetime).calendar() ?? ''}`
      : type.display;
  return (
    <Tooltip label={tooltipLabel}>
      <span>{type.icon && <Icon as={type.icon} />}</span>
    </Tooltip>
  );
};

export default RuleTypeElement;
