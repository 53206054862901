import { Menu, MenuButton, Button, MenuList, MenuItem } from '@chakra-ui/react';
import { FC } from 'react';
import { FiChevronDown } from 'react-icons/fi';

type MenuActionItem = {
  text: string;
  action: () => void;
};

type MenuActionsProps = {
  items: MenuActionItem[];
  isDisabled?: boolean;
  children?: React.ReactNode
};
const MenuActions: FC<MenuActionsProps> = ({
  items,
  isDisabled = false,
  children,
}) => {
  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<FiChevronDown />}
          disabled={isDisabled}
        >
          Actions...
        </MenuButton>
        <MenuList>
          {items.map((item, i) => {
            return (
              <MenuItem key={i} onClick={item.action}>
                {item.text}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      {children}
    </>
  );
};

export default MenuActions;
