import ApiClient from '../api/ApiClient';
import { fetchRelatedRuleDto, RuleDto, UpdateRuleDto } from './types';

export class RulesService {
  private readonly apiBase: string;
  private readonly apiClient: ApiClient;

  constructor() {
    this.apiBase = 'rules';
    this.apiClient = new ApiClient();
  }

  async updateRule(id: number, updateRuleDto: UpdateRuleDto) {
    return this.apiClient.patch<UpdateRuleDto, RuleDto>(`${this.apiBase}/${id}`, updateRuleDto);
  }

  async updateRules(updateRuleDtos: UpdateRuleDto[]) {
    return this.apiClient.patch<UpdateRuleDto[], RuleDto[]>(`${this.apiBase}`, updateRuleDtos);
  }

  async fetchRules(query: fetchRelatedRuleDto): Promise<RuleDto> {
    return this.apiClient.get(`${this.apiBase}`, { params: query });
  }
}
