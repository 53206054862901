import { WarningIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    Text,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Unauthorized: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container maxW="container.sm">
      <Center h="80vh">
        <Box 
          p={8} 
          boxShadow="md" 
          borderWidth="1px" 
          borderRadius="md" 
          bg="white" 
          width="100%" 
          maxW="500px"
        >
          <VStack spacing={4} align="center">
            <WarningIcon boxSize={16} color="red.500" />
            
            <Heading as="h1" size="lg">
              Access Denied
            </Heading>
            
            <Text color="gray.600" textAlign="center">
              You don't have permission to access this page. Please contact your administrator if you believe this is an error.
            </Text>
            
            <Box mt={4}>
              <Button
                colorScheme="blue"
                onClick={() => navigate('/')}
              >
                Go to Home
              </Button>
            </Box>
          </VStack>
        </Box>
      </Center>
    </Container>
  );
};

export default Unauthorized;