import { Card, CardBody, CardFooter, CardHeader, Heading, Icon, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import moment from "moment";
import RuleType from "../../common/RuleType";
import { ProductDto } from "../../core/products/types";

interface RuleViewerProps {
	product: ProductDto
}

const RuleViewer = ({ product }: RuleViewerProps) => {
	const ruleType = RuleType.fromName(product.rule?.type)

	return (
		<Card>
				<CardHeader>
					<Heading as="h4" size="md">
					{ruleType.icon && <Icon as={ruleType.icon} mr={4} />}
					{`The stock rule of this product is set to ${ruleType.display}`}
					</Heading>
				</CardHeader>
				<CardBody>
					<UnorderedList>
						{ruleType === RuleType.Manual && 
							<>
							<ListItem>
								{`The quantity has been set to `}
								<Text as="b">
									{`${product?.rule?.quantity} piece(s)`}
								</Text>
								{`...`}
							</ListItem>
							<ListItem>
								{`We currently have `}
								<Text as="b">
									{`${product?.sf_quantity} piece(s)`}
								</Text>
								{` to sell.`}
							</ListItem>
							</>
						}
						{ruleType === RuleType.Perpetual && 
							<ListItem>
								{`The product can be sold `}
								<Text as="b">
									{`regardless of the stock level`}
								</Text>
								{`.`}
							</ListItem>
						}
						{ruleType === RuleType.Automatic && 
							<ListItem>
								{`The availability of the product `}
								<Text as="b">
									{`is linked to your E-commerce Storage Location`}
								</Text>
								{`.`}
							</ListItem>
						}
						{ruleType !== RuleType.Automatic &&
							<ListItem>
								{`This rule expires on the `}
								<Text as={'b'}>{moment(product.rule?.lifetime).format('DD.MM.YYYY hh:mm')}</Text>
								{`. It will be considered as Automatic afterwards.`}
							</ListItem>
						}
						<ListItem>
							{`There are currently `}
							<Text as="b">
								{`${product.automatic_quantity} piece(s)`}
							</Text>
							{` in your E-commerce Storage Location.`}
						</ListItem>
					</UnorderedList>
				</CardBody>
				<CardFooter alignSelf={'end'}>
				{product.rule?.updatedAt && (
          <Text>{`Rule updated: ${moment(product.rule?.updatedAt).calendar()}`}</Text>
        )}
				</CardFooter>
			</Card>
	)
}

export default RuleViewer