import { Box, Flex, Show } from '@chakra-ui/react';
import { FC } from 'react';
import { ProductDto } from '../../core/products/types';
import { Field } from '../misc/Fields';

type ProductInfoProps = Pick<
  ProductDto,
  | 'name'
  | 'collection_code'
  | 'brand'
  | 'ean'
  | 'upc'
  | 'price'
  | 'country'
  | 'sales_status'
  | 'order_status'
>;
const formatPrice = (
  price: number,
  country?: { locales?: string[]; currency?: string },
): string => {
  let formattedPrice = '-';

  if (country && country.locales?.[0] && country.currency && price && price > 0) {
    formattedPrice = new Intl.NumberFormat(country.locales[0], {
      style: 'currency',
      currency: country.currency,
    }).format(price);
  }

  return formattedPrice;
};

const ProductInfo: FC<ProductInfoProps> = ({
  name,
  collection_code,
  brand,
  ean,
  upc,
  price,
  country,
  sales_status,
  order_status,
}) => {
  return (
    <Flex direction={'column'} gap="0.5em">
      <Field name="Name" value={name} />
      <Field name="Collection" value={collection_code} />
      <Field name="Brand" value={brand} />
      <Field name="Price Country" value={formatPrice(Number(price), country)} />
      <Field name="Sales & Order Status" value={`${sales_status ?? '-'} ${order_status ?? '-'}`} />
      <Box h={'1em'} />
      <Show above="sm">
        <Field name="EAN" value={ean} />
        <Field name="UPC" value={upc} />
      </Show>
    </Flex>
  );
};

export default ProductInfo;
