import ApiClient from '../api/ApiClient';
import { DataList } from '../api/types';
import { RetailStockDto, StoreInfoDto } from './types';

export class RetailStockService {
  private readonly apiBase: string;
  private readonly apiClient: ApiClient;

  constructor() {
    this.apiBase = 'retailstock';
    this.apiClient = new ApiClient();
  }

  async getRetailStock(
    country: string,
    sku?: string,
    store?: string,
    offset = 0,
    limit = 15,
  ): Promise<DataList<RetailStockDto>> {
    const config = {
      params: {
        Country: country,
        ...(sku ? { SKU: sku } : {}),
        ...(store ? { Store: store } : {}),
        offset,
        limit,
      },
    };
    return this.apiClient.get(this.apiBase, config);
  }

  async exportRetailStock(
    country: string,
    sku?: string,
    store?: string,
  ): Promise<RetailStockDto[]> {
    // Récupérer d'abord le total des résultats
    const initialResponse = await this.getRetailStock(country, sku, store, 0, 1);
    const total = initialResponse.total;

    // Récupérer toutes les données en une seule requête
    const fullData = await this.getRetailStock(country, sku, store, 0, total);
    return fullData.data;
  }

  async getAvailableCountries(): Promise<string[]> {
    return this.apiClient.get(`${this.apiBase}/countries`);
  }

  async getAvailableStores(country: string): Promise<StoreInfoDto[]> {
    return this.apiClient.get(`${this.apiBase}/stores/${country}`);
  }
}
