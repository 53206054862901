import RuleType from '../../common/RuleType';
import { ProductDto } from '../../core/products/types';

export const isActive = (
  p: Pick<ProductDto, 'country' | 'is_searchable' | 'is_archived' | 'is_available'>,
): boolean | undefined => {
  return p.country !== undefined
    ? (p.is_searchable ?? true) && // by default true on SF
        !(p.is_archived ?? false) && //
        (p.is_available ?? false) //
    : undefined;
};

export const isPurchasable = (
  p: Pick<
    ProductDto,
    'country' | 'is_searchable' | 'is_archived' | 'is_available' | 'is_perpetual' | 'sf_quantity'
  >,
): boolean | undefined => {
  return isActive(p) && (p.is_perpetual || (p?.sf_quantity ?? 0) > 0);
};

export type SortFn = (a: ProductDto, b: ProductDto) => number;
export const defaultSort: SortFn = () => 1;

export type SortType = {
  name: string;
  sortFn: () => SortFn;
};

export type SortAscendingFn = (a: ProductDto, b: ProductDto, reverse?: boolean) => number;

export type SortAscendingType = {
  name: string;
  sortAscendingFn: SortAscendingFn;
};

const compareString = (a: string, b: string, reverse: boolean = false) =>
  a.localeCompare(b) * (reverse ? -1 : 1);
export const sortById: SortAscendingFn = (a, b, reverse) => compareString(a.id, b.id, reverse);
export const sortByCountryCode: SortAscendingFn = (a, b, reverse) =>
  compareString(a.country?.code ?? '', b.country?.code ?? '', reverse);

const compareNumber = (a: number, b: number, reverse: boolean = false) =>
  (a - b) * (reverse ? -1 : 1);
export const sortBySalesforce: SortAscendingFn = (a, b, reverse) =>
  compareNumber(a.sf_quantity ?? 0, b['sf_quantity'] ?? 0, reverse);
export const sortByManual: SortAscendingFn = (a, b, reverse) =>
  compareNumber(a.rule?.quantity ?? 0, b.rule?.quantity ?? 0, reverse);
export const sortByAutomatic: SortAscendingFn = (a, b, reverse) =>
  compareNumber(a.automatic_quantity ?? 0, b.automatic_quantity ?? 0, reverse);

export type FilterFn = (product: ProductDto) => boolean;
export const defaultFilter: FilterFn = () => true;

export type BooleanFilterFn = (product: ProductDto, is: boolean) => boolean;
export const isEulogFilter: BooleanFilterFn = (value, is) =>
  (value.country?.isEulog || false) === is;

export type RuleTypeFilterFn = (product: ProductDto, is: RuleType) => boolean;
export const isRuleTypeFilter: RuleTypeFilterFn = (product, is) =>
  RuleType.fromName(product.rule?.type) === is;

export const sortByIsPurchasable: SortAscendingFn = (a, b, reverse) => {
  const aIsPurchasable = isPurchasable(a) ? 1 : 0;
  const bIsPurchasable = isPurchasable(b) ? 1 : 0;
  return compareNumber(aIsPurchasable, bIsPurchasable, reverse);
};

export const sortByRuleType: SortAscendingFn = (a, b, reverse) =>
  compareString(a.rule?.type ?? '', b.rule?.type ?? '', reverse);

export const sortByRetailStock: SortAscendingFn = (a, b, reverse) =>
  compareNumber(a.totalStock ?? 0, b.totalStock ?? 0, reverse);

export const sortByTotalStore: SortAscendingFn = (a, b, reverse) =>
  compareNumber(a.storeCount ?? 0, b.storeCount ?? 0, reverse);
