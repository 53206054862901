import ApiClient from '../api/ApiClient';

export type Role = {
  canEdit: boolean;
  canView: boolean;
  countries: Array<string>;
};

export class AuthService {
  private readonly apiBase: string;
  private readonly apiClient: ApiClient;

  constructor() {
    this.apiBase = 'auth';
    this.apiClient = new ApiClient();
  }

  async fetchRoles(): Promise<Role> {
    return this.apiClient.get(this.apiBase);
  }
}
