import { VStack, Heading } from '@chakra-ui/react';

interface ProductDetailHeaderProps {
  productId: string;
  inventoryId?: string;
}
const ProductDetailHeader = ({
  productId,
  inventoryId = undefined,
}: ProductDetailHeaderProps) => {
  return (
    <VStack align="strech" spacing="0.5em">
      <Heading as="h3" size="lg">
        {productId}
      </Heading>
      {inventoryId && (
        <Heading as="h4" size="md">
          {inventoryId}
        </Heading>
      )}
    </VStack>
  );
};

export default ProductDetailHeader;
