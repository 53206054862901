import { Center, Flex, SkeletonCircle } from '@chakra-ui/react';
import ResultTableRow from './ResultTableRow';

interface ResultTableProps {
  isLoading: boolean;
  items?: React.ReactNode[];
}
const ResultTable = ({ isLoading, items }: ResultTableProps) => {
  const rows = items?.map((item, i) => <ResultTableRow key={i}>{item}</ResultTableRow>);

  if (isLoading) {
    return (
      <Center>
        <SkeletonCircle size={'50'} startColor={'whiteAlpha.100'} endColor={'yellow.400'} />
      </Center>
    );
  }

  return <Flex direction={'column'}>{rows}</Flex>;
};

export default ResultTable;
