import { Box, BoxProps, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { exportToClipboard, exportToExcel } from '../../common/export';
import { ConvertToExport } from '../../common/product/export';
import { FilterFn, defaultFilter } from '../../common/product/utils';
import { useSearch } from '../../contexts/SearchContext';
import { useSorting } from '../../contexts/SortingContext';
import { ProductDto } from '../../core/products/types';
import OrdersHistory from '../Orders/OrdersHistory';
import RulesEditor from '../Rule/RulesEditor';
import ResultTable from '../Table/ResultTable';
import MenuActions from '../UI/MenuActions';
import ProductStocksHeader from './ProductStocksHeader';
import ProductStocksItem from './ProductStocksItem';

type ProductStockProps = {
  products: ProductDto[];
  onRowClicked: (productId: string, countryCode: string) => void;
} & BoxProps;
const ProductStock: FC<ProductStockProps> = ({ products, onRowClicked, ...rest }) => {
  const [rows, setRows] = useState<any[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenOrders, onOpen: onOpenOrders, onClose: onCloseOrders } = useDisclosure();
  const { role } = useSearch();
  const { sort } = useSorting();
  const [selectedCountryCodes, setSelectedCountryCodes] = useState<string[]>([]);
  const toggleCountry = useCallback(
    (countryCode: string, selected: boolean) => {
      if (selected && !selectedCountryCodes.includes(countryCode)) {
        setSelectedCountryCodes(prev => [...prev, countryCode]);
      } else if (!selected) {
        setSelectedCountryCodes(prev => prev.filter(c => c !== countryCode));
      }
    },
    [selectedCountryCodes],
  );

  const [filter, setFilter] = useState<FilterFn>(() => defaultFilter);
  const handleFilterChanged = useCallback((filterFn: FilterFn) => {
    setFilter(() => filterFn);
  }, []);

  useEffect(() => {
    setRows(
      products
        .filter(filter)
        .sort((a, b) => sort.sortFn()(a, b))
        .map((product, i) => {
          return (
            <ProductStocksItem
              key={i}
              {...product}
              isChecked={
                !!product.country?.code && selectedCountryCodes.includes(product.country?.code)
              }
              toggle={toggleCountry}
              onClick={onRowClicked}
            />
          );
        }),
    );
  }, [products, sort, onRowClicked, selectedCountryCodes, toggleCountry, filter]);

  const getExportProducts = () => {
    return products
      .filter(filter)
      .filter(p => !!p.country?.code && selectedCountryCodes.includes(p.country?.code))
      .sort((a, b) => sort.sortFn()(a, b))
      .map(p => ConvertToExport(p));
  };

  const copyAction = () => {
    const exportProducts = getExportProducts();
    const excelText = exportToClipboard(exportProducts);
    navigator.clipboard
      .writeText(excelText)
      .then(() => {
        console.log('has copied !');
      })
      .catch(e => console.error('An error occured while copying', e));
  };

  const exportToExcelAction = () => {
    const exportProducts = getExportProducts();
    exportToExcel(exportProducts, products[0].id);
  };

  const menuItems = [
    ...(role.canEdit
      ? [
          {
            text: 'Add a rule for...',
            action: onOpen,
          },
          {
            text: 'Search orders...',
            action: onOpenOrders,
          },
        ]
      : []),
    ...(selectedCountryCodes.length !== 0
      ? [
          {
            text: 'Copy...',
            action: copyAction,
          },
          {
            text: 'Export...',
            action: exportToExcelAction,
          },
        ]
      : []),
  ];

  return (
    <Box {...rest}>
      <Flex w={'100%'} my="2" alignItems={'baseline'} justifyContent={'space-between'}>
        <Heading as="h4" size="md">
          Inventories
        </Heading>
        <MenuActions
          items={menuItems}
          isDisabled={!role.canEdit && selectedCountryCodes.length === 0}
        >
          <RulesEditor
            selectedSkus={[products[0].id]}
            selectedCountries={selectedCountryCodes}
            isOpen={isOpen}
            onClose={onClose}
          />
          <OrdersHistory
            sku={products[0].id}
            country={selectedCountryCodes[0]}
            isOpen={isOpenOrders}
            onClose={onCloseOrders}
          />
        </MenuActions>
      </Flex>
      <ProductStocksHeader
        selectionStatus={
          selectedCountryCodes.length === 0
            ? 'none'
            : selectedCountryCodes.length === products.length
              ? 'all'
              : 'some'
        }
        selectAll={(selected: boolean) => {
          products.filter(filter).forEach(product => {
            product.country?.code && toggleCountry(product.country?.code, selected);
          });
        }}
        setFilter={handleFilterChanged}
      />
      <ResultTable items={rows} isLoading={false} />
    </Box>
  );
};

export default ProductStock;
