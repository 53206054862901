import ApiClient from '../api/ApiClient';
import { OrdersDto, OrdersFilterDto } from './types';

export class OrdersService {
  private readonly apiBase: string;
  private readonly apiClient: ApiClient;

  constructor() {
    this.apiBase = 'orders';
    this.apiClient = new ApiClient();
  }

  async searchOrders(filter: OrdersFilterDto): Promise<OrdersDto[]> {
    return this.apiClient.post(this.apiBase, filter);
  }
}
