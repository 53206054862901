import { CloseIcon, HamburgerIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { IconType } from 'react-icons';
import { FaStoreAlt } from 'react-icons/fa';
import { FiLogOut, FiPackage } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';

interface LinkItemProps {
  name: string;
  path: string;
  icon: IconType;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Ecom Stock', path: '/', icon: FiPackage },
  { name: 'Retail Stock', path: '/retail-stock', icon: FaStoreAlt },
];

// Fonction utilitaire pour déterminer si un chemin est actif
const isPathActive = (itemPath: string, currentPath: string): boolean => {
  if (itemPath === '/') {
    return currentPath === '/';
  }
  return currentPath.startsWith(itemPath);
};

type NavLinkProps = {
  path: string;
  toggle?: () => void;
  children?: React.ReactNode;
  isActive?: boolean;
};

const NavLink: FC<NavLinkProps> = ({ path, toggle, children, isActive = false }) => {
  const { logout } = useAuth();

  const handleClick = (e: React.MouseEvent) => {
    if (path === 'logout') {
      e.preventDefault();
      logout();
    } else if (toggle) {
      toggle();
    }
  };

  return (
    <Link
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('yellow.200', 'yellow.400'),
      }}
      href={path === 'logout' ? '#' : path}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

// Composant pour le texte du lien de navigation
const NavLinkText: FC<{ path: string; name: string; icon?: IconType }> = ({
  path,
  name,
  icon: Icon,
}) => {
  const location = useLocation();
  const active = isPathActive(path, location.pathname);

  return (
    <Flex alignItems="center" gap={2}>
      {Icon && <Icon />}
      <Text
        borderBottom={active ? '1px solid' : 'none'}
        borderBottomColor={active ? 'blue.400' : 'transparent'}
        _hover={{
          borderBottom: 'none',
        }}
      >
        {name}
      </Text>
    </Flex>
  );
};

type NavbarProps = {
  title: string;
  children?: React.ReactNode;
};

const Navbar: FC<NavbarProps> = ({ title, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Image
              boxSize="100px"
              objectFit="contain"
              src={useColorModeValue('swatch-logo-black.svg', 'swatch-logo-white.svg')}
              alt="Swatch Logo"
            />
            <Box>{title}</Box>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}
              borderBottom={'1px solid yellow.400'}
            >
              {LinkItems.map((item, key) => (
                <NavLink key={key} path={item.path}>
                  <NavLinkText path={item.path} name={item.name} icon={item.icon} />
                </NavLink>
              ))}
            </HStack>
          </HStack>

          <Flex alignItems={'center'} gap={2}>
            <NavLink path="#" toggle={toggleColorMode}>
              {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            </NavLink>
            <NavLink path="logout">
              <FiLogOut />
            </NavLink>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {LinkItems.map(item => (
                <NavLink key={item.name} path={item.path}>
                  <NavLinkText path={item.path} name={item.name} icon={item.icon} />
                </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Box p={4}>{children}</Box>
    </>
  );
};

export default Navbar;
