import {
  Flex,
  Icon,
  useBoolean,
  useColorModeValue,
  useUpdateEffect,
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  TiArrowUnsorted,
  TiArrowSortedDown,
  TiArrowSortedUp,
} from 'react-icons/ti';
import { SortAscendingType } from '../../common/product/utils';
import { useSorting } from '../../contexts/SortingContext';

type SortableColumnHeaderProps = {
  sortAscending: SortAscendingType;
  children?: React.ReactNode
};
const SortableColumnHeader: FC<SortableColumnHeaderProps> = ({
  sortAscending,
  children,
}) => {
  const { sort: currentSort, setSort } = useSorting();
  const [asc, { toggle }] = useBoolean(true);

  const colorActive = useColorModeValue('blackAlpha.900', 'whiteAlpha.900');
  const colorInactive = useColorModeValue('blackAlpha.300', 'whiteAlpha.300');

  useUpdateEffect(() => {
    setSort({
      name: sortAscending.name,
      sortFn: () => (a, b) => sortAscending.sortAscendingFn(a, b, !asc),
    });
  }, [asc, setSort]);

  return (
    <Flex direction={'row'} onClick={() => toggle()} cursor={'pointer'}>
      {children}
      <Flex direction={'column'}>
        {currentSort.name === sortAscending.name ? (
          asc ? (
            <Icon translateY={'50%'} as={TiArrowSortedUp} color={colorActive} />
          ) : (
            <Icon
              translateY={'-50%'}
              as={TiArrowSortedDown}
              color={colorActive}
            />
          )
        ) : (
          <Icon translateY={'50%'} as={TiArrowUnsorted} color={colorInactive} />
        )}
      </Flex>
    </Flex>
  );
};

export default SortableColumnHeader;
