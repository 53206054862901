import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { ProductUpdateDto } from '../../core/products/types';

type EditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  options: { label: string; value: string | undefined }[];
  selectedField: { label: string; value: string; locale?: string[] };
  subheader: string;
  productId: string;
  update: (productId: string, body: ProductUpdateDto) => void;
};

const EditModal: FC<EditModalProps> = ({
  isOpen,
  onClose,
  header,
  options,
  selectedField,
  subheader,
  productId,
  update,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const { value, getCheckboxProps } = useCheckboxGroup();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    let boolValue: string | boolean | null;

    if (selectedOption === 'true' || selectedOption === '1') {
      boolValue = true;
    } else if (selectedOption === 'false' || selectedOption === '0') {
      boolValue = false;
    } else if (selectedOption === 'null') {
      boolValue = null;
    } else if (selectedOption === undefined) {
      boolValue = null;
    } else {
      boolValue = selectedOption;
    }

    if (selectedField.locale) {
      let localeObj: { [key: string]: typeof boolValue } = {};
      for (let loc of selectedField.locale) {
        localeObj[loc] = boolValue;
      }
      let updateObj = { [selectedField.value]: localeObj };
      if (selectedField.value === 'c_p40ContentAsset') {
        let p40DeliveryLocaleObj: { [key: string]: string } = {};
        for (let loc of selectedField.locale) {
          p40DeliveryLocaleObj[loc] = boolValue === null ? '0' : '1';
        }
        updateObj['c_isP40Delivery'] = p40DeliveryLocaleObj;
      } else if (selectedField.value === 'c_isAlertStock') {
        let alertStockLocaleObj: { [key: string]: string | null } = {};
        for (let loc of selectedField.locale) {
          let value;
          if (boolValue === null) {
            value = null;
          } else if (boolValue === true) {
            value = '1';
          } else {
            value = '0';
          }
          alertStockLocaleObj[loc] = value;
        }
        updateObj['c_isAlertStock'] = alertStockLocaleObj;
      }
      update(productId, updateObj);
    } else if (selectedField.value === 'c_productTypePurchase') {
      update(productId, { [selectedField.value]: value });
    } else {
      update(productId, { [selectedField.value]: boolValue });
    }
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered size={'xl'}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
      <ModalContent>
        <ModalHeader>
          {header}
          <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{subheader}</p>
        </ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <FormControl>
            <ModalBody pb={6}>
              {selectedField?.value !== 'c_productTypePurchase' && (
                <Select placeholder="Select option" value={selectedOption} onChange={handleChange}>
                  {options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              )}
              {selectedField?.value === 'c_productTypePurchase' && (
                <Box p={4}>
                  <CheckboxGroup>
                    <SimpleGrid columns={2} spacing={8}>
                      {options.map((option, index) => (
                        <Checkbox
                          key={index}
                          value={option.value}
                          {...getCheckboxProps({ value: option.value })}
                        >
                          {option.label}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </CheckboxGroup>
                </Box>
              )}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Update
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </FormControl>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
