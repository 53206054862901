import ApiClient from '../api/ApiClient';
import { DataList, Query } from '../api/types';
import { ProductInventoryRecord } from './types';

export class InventoriesService {
  private readonly apiBase: string;
  private readonly apiClient: ApiClient;

  constructor() {
    this.apiBase = 'inventories';
    this.apiClient = new ApiClient();
  }

  async fetchAll(query: Query): Promise<DataList<string>> {
    const config = Object.keys(query).length
      ? {
          params: {
            ...query,
          },
        }
      : undefined;
    return this.apiClient.get(this.apiBase, config);
  }

  async fetchProductInventoryRecords(
    inventoryId: string,
    query: Query,
  ): Promise<DataList<ProductInventoryRecord>> {
    const config = Object.keys(query).length
      ? {
          params: {
            ...query,
          },
        }
      : undefined;
    return this.apiClient.get(`${this.apiBase}/${inventoryId}`, config);
  }
}
