import { Circle, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { FC } from 'react';
import { isPurchasable } from '../../common/product/utils';
import { ProductDto } from '../../core/products/types';
import ProductEcomInfo from './ProductEcomInfo';

type ProductIsPurchasablePinProps = Pick<
  ProductDto,
  | 'country'
  | 'is_searchable'
  | 'is_archived'
  | 'is_available'
  | 'is_perpetual'
  | 'sf_quantity'
  | 'e_reservation'
  | 'is_p40'
  | 'id'
>;
const ProductIsPurchasablePin: FC<ProductIsPurchasablePinProps> = p => {
  const isPurchasableValue = isPurchasable(p);
  const pinColor =
    isPurchasableValue !== undefined ? (isPurchasableValue ? 'green.300' : 'red.600') : 'gray.500';

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Circle size={'1em'} bg={pinColor} />
      </PopoverTrigger>
      <PopoverContent>
        <ProductEcomInfo {...p} m={2} gap={1} />
      </PopoverContent>
    </Popover>
  );
};

export default ProductIsPurchasablePin;
